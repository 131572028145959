import React from 'react';
import { Radar } from 'react-chartjs-2';

const datos = {
  labels: ['Comer', 'Beber', 'Dormir', 'Diseñar', 'Codificar', 'Ciclismo', 'Correr'],
  datasets: [
    {
      label: 'Persona A',
      backgroundColor: 'rgba(179,181,198,0.2)',
      borderColor: 'rgba(179,181,198,1)',
      pointBackgroundColor: 'rgba(179,181,198,0.5)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(179,181,198,1)',
      data: [65, 59, 90, 81, 56, 55, 40]
    },
    
  ]
};

const options = {
  scales: {
    r: {
      grid: {
        color: 'rgba(255, 255, 255, 0.2)',// Cambia el color aquí, en este caso a blanco con 20% de opacidad
        drawOnChartArea: false,

      },
      angleLines: {
        color: 'rgba(255, 255, 255, 0.2)' // Cambia el color de las líneas angulares, en este caso a blanco con 20% de opacidad
      },
      ticks: {
        display: false, // No muestra las etiquetas de los ticks
      }
    }
  }
};

const RadarChart = ({ data }) => {
  //console.log("data radar chart", data)
  const contents = data.data.content_completed;
if (contents) {
    let entries = Object.entries(contents);
    // Resto del código que usa `entries`
} else {
    console.log('contents is null or undefined');
    // Código alternativo para manejar el caso en que `contents` es null o undefined
}  
  let keys = ["Listas", "Bucles", "Condicionales", "Functions", "Int", "Enteros"];
  let values = {};
  keys.forEach(key => {
    if (contents && contents[key] !== undefined) {
      values[key] = contents[key];
    } else {
      console.log(`Key ${key} not found in contents`);
      values[key] = 0; // or any default value
    }
  });

  let datos = {
    labels: keys,
    datasets: [
      {
        label: "Intentos exitosos por contenido",
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,0.5)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: Object.values(values)
      },
      ]
  };
  

  

  /* // Ordenar el array por valor de forma descendente
  entries.sort((a, b) => b[1] - a[1]);

  // Seleccionar los primeros 6 elementos
  let top6 = entries.slice(0, 6);

  console.log(top6); */

  
  return(
    <div>
      <Radar data={datos} options={options} />
    </div>);
}

export default RadarChart;
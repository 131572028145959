import React from 'react';
import { FaCheckCircle, FaTimesCircle, FaExclamationCircle } from 'react-icons/fa';

const TestCases = ({ testCases }) => {
  return (
    <div className="test-cases-container space-y-4">
      {testCases.map((testCase, index) => (
        <div key={index} className={`test-case p-4 rounded-lg border-2 ${testCase.success ? 'border-green-500' : 'border-red-500'}`}>
          <div className="test-case-header flex items-center mb-2">
            {testCase.success ? (
              <FaCheckCircle className="text-green-500 mr-2" />
            ) : (
              <FaTimesCircle className="text-red-500 mr-2" />
            )}
            <span className="font-bold">Test case {index}</span>
          </div>
          {testCase.message && (
            <div className="compiler-message flex items-center mb-2">
              <FaExclamationCircle className="text-yellow-500 mr-2" />
              <span>{testCase.message}</span>
            </div>
          )}
          <div className="test-case-body">
            <div className="mb-2">
              <span className="font-bold">Input (stdin)</span>
              <div className="bg-gray-100 p-2 rounded mt-1 font-mono text-sm">
                {testCase.input}
              </div>
            </div>
            <div>
              <span className="font-bold">Expected Output</span>
              <div className="bg-gray-100 p-2 rounded mt-1 font-mono text-sm">
                {testCase.expectedOutput}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestCases;

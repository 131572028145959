import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../features/auth/api';
import { jwtDecode } from 'jwt-decode';
import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { Button } from '@mui/material';
import { sub } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { loadLanguage } from "@uiw/codemirror-extensions-langs";

async function getProblems(subject) {
    const data = await api.get(`/exercise/list/${subject}`);
    //console.log('data', data);
    return data.data.results;
}

const BrowseProblems = () => {
    const [problemas, setProblemas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentProblem, setCurrentProblem] = useState(null);
    const [selectedDifficulty, setSelectedDifficulty] = useState('Todas');
    const [currentPage, setCurrentPage] = useState(1);
    const [problemsPerPage] = useState(10);

    useEffect(() => {

        const tokenData = jwtDecode(localStorage.getItem('token'));
        if (tokenData && tokenData.user_data) {
        } else {
            //console.log("No hay datos de usuario");
        }

        const fetchData = async () => {
            let subject = tokenData.user_data.subject.subject;
            console.log('subject', subject);
            const problemsData = await getProblems('TEL101');
            setProblemas(problemsData);
        };
        fetchData();
    }, []);




    const filteredProblems = problemas.filter((problem) => {
        return selectedDifficulty === 'Todas' || problem.difficulty === selectedDifficulty;
    });

    const pageCount = Math.ceil(filteredProblems.length / problemsPerPage);

    const indexOfLastProblem = currentPage * problemsPerPage;
    const indexOfFirstProblem = indexOfLastProblem - problemsPerPage;
    const currentProblems = filteredProblems.slice(indexOfFirstProblem, indexOfLastProblem);

    const handleSolveProblem = (item) => {

        if (item.exercise_dependency && !currentProblems.find(problem => problem.exercise_id === item.exercise_dependency).user_attempts_info.resolved) {
            return;
        }

        setCurrentProblem(item);
        setShowModal(true);

    };

    const handleDifficultyChange = (difficulty) => {
        setSelectedDifficulty(difficulty);
    };

    //console.log("Problemas:", currentProblems);
    return (
        <div className="p-6">
            <div className="flex justify-end">
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn m-1 mb-4">
                        Elija una dificultad
                    </div>
                    <ul
                        tabIndex={0}
                        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                    >
                        <li>
                            <a onClick={() => handleDifficultyChange("Todas")}>Todas</a>
                        </li>
                        <li>
                            <a onClick={() => handleDifficultyChange("Fácil")}>Fácil</a>
                        </li>
                        <li>
                            <a onClick={() => handleDifficultyChange("Medio")}>Medio</a>
                        </li>
                        <li>
                            <a onClick={() => handleDifficultyChange("Difícil")}>Difícil</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                {currentProblems.map((item) => (
                    item.is_visible && // {/* Solo mostrar problemas visibles */}
                    <div key={item.id} className="border rounded-lg p-4 shadow-md mb-4 w-full md:max-w-xl mx-auto">
                        <div className="flex items-center">
                            <h2 className="text-xl font-bold mb-2 hover:text-gray-500 cursor-pointer" onClick={() => handleSolveProblem(item)}>
                                {item.title}
                            </h2>
                            {item.user_attempts_info.resolved ?
                                <BsCheckCircleFill className="text-green-500 ml-2" /> :
                                (item.user_attempts_info.total_attempts > 0 ? <BsFillXCircleFill className="text-red-600 ml-2" /> : null)
                            }
                        </div>

                        <div className="flex justify-between items-center mb-2">
                            <span className={`inline-block px-2 py-1 rounded-full text-xs font-bold ${item.difficulty === 'Fácil' ? 'bg-green-200 text-green-700 mx-1' :
                                item.difficulty === 'Medio' ? 'bg-yellow-200 text-yellow-700 mx-1' :
                                    'bg-red-200 text-red-700 mx-1'
                                }`}>
                                {item.difficulty}
                            </span>

                        </div>
                        <div className=" justify-between items-center">
                            <p> Tasa de éxito: {Number(item.success_rate).toFixed(0)}%, Intentos: {item.user_attempts_info.total_attempts}</p><p>Contenidos: {item.contents}</p>
                            <div className="flex justify-end">
                            {!(item.exercise_dependency && !currentProblems.find(problem => problem.exercise_id === item.exercise_dependency)?.user_attempts_info.resolved) ? (
                                <Link to={`/problems/solve/${item.exercise_id}`}>
                                    <button
                                        className="btn btn-outline btn-success ml-5">
                                        Resolver problema
                                    </button>
                                    </Link>
                            ) : 
                            (
                                <div> 
                                    Primero debes resolver  "{currentProblems.find(problem => problem.exercise_id === item.exercise_dependency)?.title}"
                                </div>
                            )}
                            </div>   
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex justify-center join mt-8 ">
                {Array.from({ length: pageCount }, (_, i) => i + 1).map(number => (
                    <button
                        key={number}
                        onClick={() => setCurrentPage(number)}
                        className={`join-item btn ${currentPage === number ? 'btn-active' : ''}`}
                    >
                        {number}
                    </button>
                ))}
            </div>
            {
                showModal && currentProblem && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-gray-100 px-4 py-3 sm:px-6 flex justify-between items-center border-b">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                        Detalles del problema
                                    </h3>
                                    <button onClick={() => setShowModal(false)} type="button" className="text-gray-400 hover:text-gray-500">
                                        ✕
                                    </button>
                                </div>
                                <div className="p-6">
                                    <div className="flex flex-col space-y-4">
                                        <div className="flex justify-between">
                                            <span className="font-bold text-gray-900">Problema:</span>
                                            <span className="text-gray-600">{currentProblem.title}</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span className="font-bold text-gray-900">Dificultad:</span>
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${currentProblem.difficulty === 'Fácil' ? 'bg-green-100 text-green-800' :
                                                currentProblem.difficulty === 'Medio' ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-red-100 text-red-800'
                                                }`}>
                                                {currentProblem.difficulty}
                                            </span>
                                        </div>
                                        <span className="font-bold text-gray-900">Enunciado:</span>
                                         <ReactMarkdown className='text-gray-600'>{currentProblem.files_data.problem_statement}</ReactMarkdown>
                                        <span className="font-bold text-gray-900">Restricciones:</span>
                                        
                                            <ReactMarkdown className='text-gray-900'>{currentProblem.constraints}</ReactMarkdown>
                                        
                                        
                                      
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <Link to={`/problems/solve/${currentProblem.exercise_id}`}>
                                        <button type="button" className="btn btn-outline btn-success">
                                            Resolver Problema
                                        </button>
                                    </Link>
                                    <button onClick={() => setShowModal(false)} type="button" className="btn btn-outline btn-error mr-2">
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default BrowseProblems;

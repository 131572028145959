import React, { useState, useEffect } from 'react';
import TestCaseForm from './TestCaseForm';
import { useParams } from 'react-router-dom';
import DeletePopup from '../../../components/DeletePopup';
import api from '../../../features/auth/api';

const EditIcon = (isHovered) => (
  <svg
    className={`w-6 h-6 ${isHovered ? 'text-gray-800 dark:text-white hover:text-blue-700' : 'text-gray-800 dark:text-white'}`}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M7.418 17.861 1 20l2.139-6.418m4.279 4.279 10.7-10.7a3.027 3.027 0 0 0-2.14-5.165c-.802 0-1.571.319-2.139.886l-10.7 10.7m4.279 4.279-4.279-4.279m2.139 2.14 7.844-7.844m-1.426-2.853 4.279 4.279"
    />
  </svg>
);


const TrashIcon = (isHovered) => (
  <svg className={`w-[20px] h-[20px] ${isHovered ? 'fill-[#8e8e8e] hover:fill-[#bd0000]' : 'fill-[#8e8e8e]'} `} viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
  <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"></path>
  </svg>
);


const TestCase = () => {
  const { exerciseId } = useParams();
  const [testCases, setTestCases] = useState([]);
  const [showForm, setShowForm] = useState(false);

  //Archivo
  const [file, setFile] = useState(null);

  //Para pintar el lapiz
  const [isHovered, setIsHovered] = useState([]);

  //Mensaje de warning
  const [warningMessage, setWarningMessage] = useState("");


  const handleNewTestCase = (newTestCase) =>{
    //console.log("aca el newtestcase en las listas", newTestCase)
    //console.log("aca los testcases", testCases)
    setTestCases([...testCases, newTestCase]);
    //console.log("despues de añadirlo?", testCases)
    setShowForm(false);
  }

/*   const handleNewTestCase = (newTestCase) => {
    setTestCases(prevTestCases => [...prevTestCases, newTestCase]);
  }; */

  // Para editar los casos de prueba
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  const handleEditTestCases = (testCase)=>{
    setSelectedTestCase(testCase);
    setShowForm(true);
    
  }

  const handleTestCaseUpdated = (updatedTestCase) => {
    setTestCases(testCases.map(test => test.id === updatedTestCase._id ? updatedTestCase : test));
    setShowForm(false); 
    setSelectedTestCase(null); 
  };

  useEffect(() => {
    const getTestCases = async () => {
      try {
        //const url = `http://localhost:4000/exercises/${exerciseId}/testcases`;
        const url = `https://backend.teloprogramo.cl/api/exercise/use_cases/${exerciseId}/`;

        const response = await api.get(url);
        if (response.status != 200) throw new Error('Failed to fetch');
        const data = await response.data;
        setTestCases(data);
      } catch (error) {
        console.error(error);
      }
    };
  
    if (exerciseId) {
      getTestCases();
    }
  }, [exerciseId]);


/*   const handleDeleteTestCases = async (testCase) => {
    console.log(exerciseId);
    try {
      //const url = `http://localhost:4000/exercises/${exerciseId}/testcases/${testCaseToDeleteId}`
      const url = `https://backend.teloprogramo.cl/api/exercise/use_cases/${exerciseId}/${testCaseToDeleteId}/`
      const response = await api.delete(url);

      console.log("aca la repsonse", response)
      if (response.status === 202) {
        setTestCases(testCases.filter((test) => test.id !== testCaseToDeleteId));

      } else {
        throw new Error('Deletion failed');
      }

    } catch (error) {
      console.error('Error during deletion:', error);
    }
    closeDeleteModal();
  }; */

  const handleDeleteTestCases = async () => {
    try {
      const deleteUrl = `https://backend.teloprogramo.cl/api/exercise/use_cases/${exerciseId}/${testCaseToDeleteId}/`;
      const deleteResponse = await api.delete(deleteUrl);
      if (deleteResponse.status === 202) {
        setTestCases(testCases.filter((test) => test.id !== testCaseToDeleteId));
        const updatePayload = {
          is_visible: false 
        };
        const updateUrl = `https://backend.teloprogramo.cl/api/exercise/update/${exerciseId}/`;
        const updateResponse = await api.put(updateUrl, updatePayload);
        if (updateResponse.status === 200) {
          //console.log("Se ha actualizado el estado de publicación");
        } else {
          throw new Error('Actualización de estado de publicación fallida');
        }
      } else {
        throw new Error('Deletion failed');
      }
    } catch (error) {
      console.error('Error during deletion:', error);
    }
    closeDeleteModal();
  };
  

  // popup para borrar
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [testCaseToDeleteId, setTestCaseToDeleteId] = useState(null);

/*   const openDeleteModal = (exerciseId) => {
    setTestCaseToDeleteId(exerciseId);
    setShowDeletePopup(true);
    console.log(showDeletePopup)
  }; */
  
  const openDeleteModal = (testCaseId) => {
    const isSafeToDelete = checkRequirementsToDelete(testCaseId);
    let warningMsg = "";
    if (!isSafeToDelete) {
      warningMsg = "Borrar este caso de prueba alteraría el estado de publicación del ejercicio. ¿Desea continuar?";
    }
    setWarningMessage(warningMsg);
    setTestCaseToDeleteId(testCaseId);
    setShowDeletePopup(true);
  };
  

  const closeDeleteModal = () => {
    setShowDeletePopup(false);
  };

  //Subir rar
  const openFileModal = () => {
    //console.log("si abre el modal")
    document.getElementById(`${exerciseId}-fileModal`).showModal();
  }
  const closeFileModal = () => {
    document.getElementById(`${exerciseId}-fileModal`).close();
  }


  const handleFileChange = (e) => {
    setFile(e.target.files[0]); 
  };

  const test = () => {
    //console.log("aca el file del test", file);
  }

  const handleFileUpload = async () => {
    if (!file) {
      alert('Por favor, seleccione un archivo primero.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file); 

    try {
      const url = `https://backend.teloprogramo.cl/api/exercise/use_cases/upload_zip/${exerciseId}`

      const response = await api.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status != 201) throw new Error('Error al subir el archivo');
      const data = await response.data;
      setTestCases(data);
      closeFileModal();

    } catch (error) {
      console.error('Error al subir el archivo:', error);
    }
  };

  //Verificacion de los casos de prueba
  const checkRequirementsToDelete = (testCaseId) => {
    const remainingTestCases = testCases.filter((testCase) => testCase.id !== testCaseId);
    const hasPublic = remainingTestCases.some((testCase) => testCase.is_sample);
    const hasPrivate = remainingTestCases.some((testCase) => !testCase.is_sample);
    
    if (!hasPublic || !hasPrivate) {
      return false;
    }
    return true;
  };
  
  //Al salir del formulario
  const handleToggleForm = () => {
    if (showForm) {
      setSelectedTestCase(null); // Resetea el caso de prueba seleccionado
    }
    setShowForm(!showForm);
  };

  return (
    <div>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
            Lista de Casos de Prueba
          </h1>
          <div className="flex flex-col items-end">
            <button 
              //onClick={() => setShowForm(!showForm)}
              onClick={handleToggleForm}
              className="btn btn-outline btn-primary"
              
              style={{marginBottom: '20px' }}
              
            >
              {showForm ? 'Salir' : 'Crear caso de prueba'}
            </button>
            {!showForm && (
              <div>
                <button className="btn" onClick={openFileModal} style={{marginBottom: '20px' }}>
                  <svg className="w-[15px] h-[15px] fill-[#8e8e8e] mr-2" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                  </svg>
                  Subir Archivo Zip
                </button>

              </div>


            )}
          </div>

        </div>

        {showForm ? (
          <TestCaseForm onNewTestCase={handleNewTestCase} testCaseToEdit={selectedTestCase} onTestCaseUpdated={handleTestCaseUpdated}/>
        ) : (
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th></th>
                  <th>Input Code</th>
                  <th>Output Code</th>
                  <th>Explanation</th>
                  <th>Sample</th>
                  <th>Strength</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {testCases.map((testCase, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{testCase.input_code}</td>
                    <td>{testCase.output_code}</td>
                    <td>{testCase.explanation}</td>
                    <td>
                      {testCase.is_sample ? (
                        <div className="flex items-center justify-center w-6 h-6 bg-green-500 rounded-full text-white">
                          ✓
                        </div>
                      ) : (
                        <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
                      )}
                    </td>
                    <td>{testCase.strength}</td>
                    <td>
                      <button onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => handleEditTestCases(testCase)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        <EditIcon isHovered={isHovered} />
                      </button>
                      <button
                        onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                        onClick={(e) => { e.stopPropagation(); openDeleteModal(testCase.id) }}
                        className="ml-2 font-medium text-red-600 dark:text-red-500 hover:underline">
                        <TrashIcon isHovered={isHovered} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {showDeletePopup && (
        <DeletePopup
        isOpen={true}
        onCancel={closeDeleteModal}
        onConfirm={handleDeleteTestCases}
        warningMessage={warningMessage}
        /> )}
      </div>

      <dialog id={`${exerciseId}-fileModal`} className="modal">
          <div className="modal-box relative w-full max-w-4xl h-auto">
            <form method="dialog">
              <button 
                type="button" 
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                onClick={closeFileModal}
              >✕</button>
            </form>
            <h3 className="font-bold text-lg">Upload Zip</h3>
            <div className="flex flex-col items-center justify-center py-4 overflow-auto">
              <div className="mb-4">
                <input 
                  type="file" 
                  className="file-input w-full max-w-xs"
                  onChange={handleFileChange}
                />                
              </div>
              <div >
                <button 
                  className='btn btn-success'
                  onClick={handleFileUpload}
                  style={{marginTop: '10px' }}
                > 
                  Enviar
                </button>
              </div>

            </div>
          </div>
        </dialog>

    </div>
  );
};

export default TestCase;

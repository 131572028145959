import React, { useEffect, useState } from "react";
import ExerciseForm from "./components/ExerciseForm";
import ProfessorNavbar from "./components/ProfessorNavBar";
import NavBar from "../../components/NavBar";
import ExerciseDetails from "./components/ExerciseDetails";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../../components/DeletePopup";
import FormWithSteps from "./components/FormWithSteps";
import api from "../../features/auth/api";
import { jwtDecode } from "jwt-decode";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";




const TrashIcon = (isHovered) => (
  <svg
    className={`w-[20px] h-[20px] ${
      isHovered ? "fill-[#8e8e8e] hover:fill-[#bd0000]" : "fill-[#8e8e8e]"
    } `}
    viewBox="0 0 448 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"></path>
  </svg>
);

const ExerciseList = () => {
  const [exercises, setExercises] = useState([]);
  const [showExerciseForm, setExerciseForm] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const navigate = useNavigate();
  //barra de busqueda
  const [searchTerm, setSearchTerm] = useState("");
  //popup para borrar
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [exerciseToDeleteId, setExerciseToDeleteId] = useState(null);

  //Cambiar color del trash
  const [isHovered, setIsHovered] = useState([]);

  //Paginación
  const exercisesPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastExercise = currentPage * exercisesPerPage;
  const indexOfFirstExercise = indexOfLastExercise - exercisesPerPage;

    //barra de busqueda
  const filteredExercises = exercises.filter((exercise) =>
    exercise.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const currentExercises = filteredExercises.slice(
    indexOfFirstExercise,
    indexOfLastExercise
  );

  //Error publish
  const [errorPublish, setErrorPublish] = useState("");

  //Success delete exercise
  const [successMessage, setSuccessMessage] = useState("");

  //Section
  const user_subject = jwtDecode(localStorage.getItem('token')).user_data.subject.subject;

  const [warningMessage, setWarningMessage] = useState("");


  //const user_subject = 'TEL-101' //revisar

  const handleSelectExercise = (exerciseId) => {
    // Redireccionar a los detalles del ejercicio
    navigate(`/exercises/${exerciseId}/details`);
  };

  const handleNewExercise = (newExercise) => {
    setExercises([...exercises, newExercise]);
    //setExerciseForm(false);
  };

  const handleExerciseUpdated = (updatedExercise) => {
    //console.log("aca el exercise al actualizar?", updatedExercise)
    setExercises(
      exercises.map((exer) =>
        exer.exercise_id === updatedExercise.exercise_id ? updatedExercise : exer
      )
    );
    setSelectedExercise(null);
  };

  const handleFinalFormUpdated = (updatedExercise) => {
    setExercises(
      exercises.map((exer) =>
        exer.exercise_id === updatedExercise.exercise_id ? updatedExercise : exer
      )
    );
    setSelectedExercise(null);
    setExerciseForm(false);
  };

  useEffect(() => {
    const getExercises = async () => {
      try {
        const url = `https://backend.teloprogramo.cl/api/exercise/list/${user_subject}`;
        const response = await api.get(url);
        if (response.status != 200) throw new Error("Failed to fetch");
        const { results } = await response.data;
        setExercises(results);
      } catch (error) {
        console.log(error);
      }
    };
    getExercises();
  }, []);

  const [pageTitle, setPageTitle] = useState("Lista de Ejercicios");

  //Borrar ejercicio
  const handleDeleteExercise = async () => {
    try {
      const url = `https://backend.teloprogramo.cl/api/exercise/delete/${exerciseToDeleteId}`;

      const response = await api.delete(url);
      if (response.status === 200) {
        setExercises(
          exercises.filter((exercise) => exercise.exercise_id !== exerciseToDeleteId)
        );
        setSelectedExercise(null);
        const exercise_name = exercises.find((exercise) => exercise.exercise_id === exerciseToDeleteId).title;
        setSuccessMessage(`Ejercicio ${exercise_name} eliminado correctamente`);
      } else {
        throw new Error("Deletion failed");
      }
    } catch (error) {
      console.error("Error during exercise deletion:", error);
    }
    closeDeleteModal();
  };

  //Timer del alert
  useEffect(() => {
    if (errorPublish ) {
      const timer = setTimeout(() => {
        setErrorPublish("");
      }, 3000);
      return () => clearTimeout(timer);
    }
    else if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
    
  }, [errorPublish, successMessage]);

  //popup para borrar
  const openDeleteModal = (exerciseId) => {
    setExerciseToDeleteId(exerciseId);
    const exercise_to_delete = exercises.find((exercise) => exercise.exercise_id === exerciseId);
    let warning_msg = "";
    if (exercise_to_delete.is_visible){
      warning_msg = "Este es un ejercicio que se encuentra publicado, al eliminarlo se borraran todas sus dependencias. ¿Desea continuar?"
    }
    setWarningMessage(warning_msg)
    setShowDeletePopup(true);
  };

  const closeDeleteModal = () => {
    setShowDeletePopup(false);
  };

  //toggle para el publish
  const handleToggleChange = async (id, e) => {
    const newPublishState = e.target.checked;
    const exercise = exercises.find((exercise) => exercise.exercise_id === id);
    if (exercise && calculateProgressPercentage(exercise) === 100) {
      setExercises(
        exercises.map((exercise) =>
          exercise.exercise_id === id
            ? { ...exercise, is_visible: newPublishState }
            : exercise
        )
      );
      try {
        const url = `https://backend.teloprogramo.cl/api/exercise/update/${id}/`;
        const response = await api.put(
          url,
          JSON.stringify({ is_visible: newPublishState })
        );

        if (!response.ok) {
          throw new Error("Submission failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      //alert("El ejercicio debe tener al menos 2 casos de pruebaaaaaaa");
      setErrorPublish(
        `No es posible publicar el ejercicio "${exercise.title}"`
      );
      e.target.checked = false;
    }

    e.stopPropagation();
  };


  //PAginacion
  //Cambiar pagina
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //Total de paginas
  const pageCount = Math.ceil(exercises.length / exercisesPerPage);

  //Progreso
  const calculateProgressPercentage = (exercise) => {
    let progressPercent = 50;
    const hasPublicTestCase = exercise.use_cases.some(
      (testCase) => testCase.is_sample
    );
    const hasPrivateTestCase = exercise.use_cases.some(
      (testCase) => !testCase.is_sample 
    );
    if (hasPublicTestCase) progressPercent += 25;
    if (hasPrivateTestCase) progressPercent += 25;

    return progressPercent;
  };

  const getProgressColor = (percentage) => {
    if (percentage < 75) {
      return "progress progress-error w-56"; //50% rojo
    } else if (percentage < 100) {
      return "progress progress-info w-56"; //75% azul
    } else {
      return "progress progress-success w-56"; //100% verde
    }
  };





  return (
    <div>
      <NavBar />
      <div className="container mx-auto p-4">
        <div className="container mx-auto p-4">
          {showExerciseForm ? null : (
            <ProfessorNavbar onSearch={(term) => setSearchTerm(term)} />
          )}
          
          <hr className="border-gray-700 my-0" />
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              {pageTitle}
            </h1>
            <button
              style={{ marginTop: "20px" }}
              onClick={() => {
                setExerciseForm(!showExerciseForm);
                setSelectedExercise(null);
                setPageTitle(
                  showExerciseForm
                    ? "Lista de Ejercicios"
                    : "Creando nuevo ejercicio"
                );
              }}
              className="btn btn-outline btn-primary"
            >
              {showExerciseForm ? "Salir" : "Añadir nuevo ejercicio"}
            </button>
          </div>
          {showExerciseForm ? (
            <FormWithSteps
              onNewExercise={handleNewExercise}
              exerciseToEdit={selectedExercise}
              onExerciseUpdated={handleExerciseUpdated}
              onFinalStepForm={handleFinalFormUpdated}
            />
          ) : selectedExercise ? (
            <ExerciseDetails
              exercise={selectedExercise}
              onBackToList={() => setSelectedExercise(null)}
            />
          ) : (
            <div className="overflow-x-auto">
              {errorPublish && (
                <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50" role="alert">
                  <div className="alert alert-error">
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span>{errorPublish}</span>
                  </div>
                </div>
              )}
              {successMessage && (
                <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50" role="alert">
                  <div role="alert" className="alert alert-success">
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span>{successMessage}</span>
                  </div>
                </div>
              )}
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th>Nombre del problema</th>
                    <th>Asignatura</th>
                    <th>Contenidos</th>
                    <th>Dificultad</th>
                    <th>Creado por</th>
                    <th>Eliminar</th>
                    <th>Publicar</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {currentExercises.map((exercise, index) => (
                    <tr
                      key={index}
                      onClick={() => handleSelectExercise(exercise.exercise_id)}
                      className="hover:bg-green-200 cursor-pointer"
                    >
                      <th>
                        {(currentPage - 1) * exercisesPerPage + index + 1}
                      </th>
                      <td>{exercise.title}</td>
                      <td>{exercise.subject}</td>
                      <td>{exercise.contents.split(',').sort().join(', ')}</td>
                      <td>{exercise.difficulty}</td>
                      <td>{exercise.username}</td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <button
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={(e) => {
                            openDeleteModal(exercise.exercise_id);
                          }}
                          className={`text-red-500 hover:text-red-700 ${
                            isHovered ? "fill-[#bd0000]" : "fill-[#8e8e8e]"
                          }`}
                        >
                          <TrashIcon />
                        </button>
                      </td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <input
                          onClick={(e) => {
                            handleToggleChange(exercise.exercise_id, e);
                          }}
                          type="checkbox"
                          className="toggle toggle-success"
                          checked={exercise.is_visible || false}
                        />
                      </td>

                      <td onClick={(e) => e.stopPropagation()}>
                        <progress
                          //onClick={(e) => {e.stopPropagation();}}
                          className={`progress w-56 ${getProgressColor(
                            calculateProgressPercentage(exercise)
                          )}`}
                          value={calculateProgressPercentage(exercise)}
                          max="100"
                        ></progress>
                      </td>
                    </tr>
                  ))}

                          {Array(10 - currentExercises.length).fill().map((_, index) => (
                            <tr key={`placeholder-${index}`} style={{ height: '3rem', backgroundColor: 'transparent', border: 'none'}}>
                              <td colSpan="11">&nbsp;</td> 
                            </tr>
                          ))}
                </tbody>
              </table>
            </div>
          )}

          {/*Paginacion*/}
          {!showExerciseForm && (
            <div className="flex justify-center mt-4">
              {[...Array(pageCount).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`join-item btn btn-square ${
                    currentPage === number + 1 ? "btn-active" : ""
                  }`}
                  aria-label={number + 1}
                >
                  {number + 1}
                </button>
              ))}
            </div>
          )}
        </div>


        {showDeletePopup && (
          <DeletePopup
            isOpen={true}
            onCancel={closeDeleteModal}
            onConfirm={handleDeleteExercise}
            warningMessage={warningMessage}
          />
        )}
      </div>
    </div>
  );
};

export default ExerciseList;

import React from 'react'
import { Link } from "react-router-dom";

const NoLoginNavbar = () => {
  return (
    <>


      <div class="navbar bg-base-100">
        <div class="flex-1">
          <a href='/' className="ml-5 text-xl font-bold">
            <span className="text-blue-500">TEL</span>OPROGRAMO
          </a>
        </div>


        <div class="flex-none lg:block">
          <ul class="menu menu-horizontal">
            {/*<li><a>Inicio</a></li>*/}
            <Link to="/auth"><button className='btn btn-outline btn-success mx-4'>Iniciar sesión</button></Link>
            <Link to="/auth/register"><button className='btn btn-outline text-blue-500 mx-4'>Registrarse</button></Link>

          </ul>
        </div>

      </div>






    </>
  )
}

export default NoLoginNavbar
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import {jwtDecode} from 'jwt-decode';
import { FaCoins } from "react-icons/fa6";
import api from '../features/auth/api';
import logo1 from '../assets/img/foto_teloprogramo_2.png';

const closeSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  window.location.href = '/';
}


const NavBar = () => {
  const rol = useSelector(state => state.user.rol);
  
  const jwtDecoded = jwtDecode(localStorage.getItem('token'));
  const user = jwtDecoded.user_data.user_id;

  const [coins, setCoins] = React.useState(0);  
  React.useEffect(() => {
    async function fetchCoins() {
        const coins = await api.get(`https://backend.teloprogramo.cl/api/user/get_coin/${user}`).then(data => {
            setCoins(data.coins);
            return data;
        });
    }
    
    fetchCoins();
}, [coins]);
const [picture, setPicture] = React.useState(null);
React.useEffect(() => {
    api.get(`https://backend.teloprogramo.cl/api/user/get-picture/${user}`).then(data => {
      const picture = decodeURIComponent(data.data.picture).slice(7).replace('https:', 'https:/');
      console.log(picture);
      setPicture(picture);
      return data;
    });
  }
  , []);

  return (
    <>
      {/*<div class="navbar bg-neutral text-neutral-content">*/}
<div className="navbar bg-base-200 dark:bg-base-100">
  <div className="flex-1">
    <Link to='/auth' className="ml-5 text-xl font-bold text-primary-dark ">
      <span className="text-blue-500">TEL</span>OPROGRAMO
    </Link>
  </div>

  <div className="flex-none lg:block">
    <ul className="menu menu-horizontal text-primary-dark ">
      {/*<li><a>Inicio</a></li>*/}
      <Link to="/problems/browse"><li><a>Problemas</a></li></Link>
      <Link to="/ranking"><li><a>Ranking</a></li></Link>
      <li
        style={{
          display: rol === 'Teacher' ? 'block' : 'none'
        }}
      >
        <details>
          <summary>
            Profesor
          </summary>
          <ul className="p-2 bg-base-200 dark:bg-base-100 rounded-t-none text-primary-dark dark:text-primary-content">
            <Link to="/professor"><li><a>Inicio</a></li> </Link>
            <Link to="/exercises"><li><a>Ejercicios</a></li> </Link>
          </ul>
        </details>
      </li> {/*debemos realizar la vista de modeloCorrector*/}
    </ul>
  </div>
  {/* <span><FaCoins style={{ marginRight: '10px' }} /> {coins}</span> */}
  <div className="flex-none">
    <div className="dropdown dropdown-end">
      <div tabindex="0" role="button" className="btn btn-ghost btn-circle avatar" >
        <div className="w-10 rounded-full">
          <img src={picture} alt='foto'/>
        </div>
      </div>
      <ul tabindex="0" className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-200 dark:bg-base-100 rounded-box w-52 text-primary-dark">
        <Link to={`/profile/${user}`}><li><a>Perfil</a></li></Link>
        <li><a onClick={closeSession}>Cerrar sesión</a></li>
      </ul>
    </div>
  </div>
</div>



    </>
  )
}

export default NavBar
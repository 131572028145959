import React, { useEffect, useState } from 'react';
import CodeEditor from './CodeEditor'; 
import {useParams} from 'react-router-dom';

import api from '../../../features/auth/api';

const TestCaseForm = ({onNewTestCase, testCaseToEdit, onTestCaseUpdated, exerciseId, onNextStep, onUpdate, inForm}) => {
  const [inputCode, setInputCode] = useState('');
  const [outputCode, setOutputCode] = useState('');
  const [explanation, setExplanation] = useState('');
  const [isSample, setIsSample] = useState(false);
  const [strength, setStrength] = useState(0);
  const { exerciseId: exerciseIdFromParams } = useParams();
  const effectiveExerciseId = exerciseId || exerciseIdFromParams;

  const [showIsSampleTooltip, setShowIsSampleTooltip] = useState(false);
  const [showStrengthTooltip, setShowStrengthTooltip] = useState(false);

  useEffect(()=>{
    if(testCaseToEdit){

      setInputCode(testCaseToEdit.input_code);
      setOutputCode(testCaseToEdit.output_code);
      setExplanation(testCaseToEdit.explanation);
      setIsSample(testCaseToEdit.is_sample || false);
      setStrength(testCaseToEdit.strength || 0);
    }
  },[testCaseToEdit]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const testCaseData = {
      input_code: inputCode,
      output_code: outputCode,
      explanation,
      is_sample: isSample,
      strength
    };
  
    if (!isFormValid(testCaseData)){
      return;
    }
  
    const url = testCaseToEdit
    ? `https://backend.teloprogramo.cl/api/exercise/use_cases/${effectiveExerciseId}/${testCaseToEdit.id}`
    : `https://backend.teloprogramo.cl/api/exercise/use_cases/${effectiveExerciseId}`;
  
    const method = testCaseToEdit ? 'PUT' : 'POST'; 
  
    const requestData = method === 'POST' ? {use_cases: [testCaseData]} : testCaseData;
    
    try {
      const response = await api({
        method,
        url,
        data: requestData
      });
  
      if ((method === 'PUT' && response.status === 200) || (method === 'POST' && response.status === 201)) {
        const result = await response.data;
        if(testCaseToEdit){
          onTestCaseUpdated(result);
        }
        else{
          if (typeof onNewTestCase === 'function') {
            //console.log("aca el resul en el testCase", result)
            onNewTestCase(result);
            //onUpdate();
          }
          if (typeof onNextStep === 'function') {
            onUpdate();
            onNextStep();
          }
        }
        // Para reiniciar el estado
        setInputCode('');
        setOutputCode('');
        setExplanation('');
        setIsSample(false);
        setStrength(0);
      } else {
        // Manejar otros códigos de estado aquí si es necesario
        throw new Error('Submission failed with status code: ' + response.status);
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };
  
  
  const [formErrors, setFormErrors] = useState({});
/*   const isFormValid = (formData) => {
    const errors = {};
    for (const key in formData) {
      //Para los textos
      if (typeof formData[key] === 'string' && !formData[key].trim()) {
        errors[key] = "Requerido";
      }
    }
    if (!formData.is_sample && (!formData.strength || formData.strength <= 0)) {
      errors.strength = "Strength debe ser mayor que 0";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }; */
  
  const isFormValid = (formData) => {
    const errors = {};
    for (const key in formData) {
      if (key !== 'explanation') {
        //Para los textos
        if (typeof formData[key] === 'string' && !formData[key].trim()) {
          errors[key] = "Requerido";
        }
      }
    }
    if (!formData.is_sample && (!formData.strength || formData.strength <= 0)) {
      errors.strength = "Strength debe ser mayor que 0";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  // *  rojo
  const requiredAsteriskStyle = { color: 'red', marginLeft: '2px' };

  //alert icon
  const alertIcon = ( 
    <svg className="w-[27px] h-[27px] fill-[#f24545]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
  </svg>
  );


//Manejo del isSample con el Strrength
const handleIsSampleChange = () => {
  setIsSample(!isSample);
  if (!isSample) {
    setStrength(0);
  }
};

const handleStrengthChange = (e) => {
  const value = e.target.value;
  const num = Number(value);
  if (!isNaN(num) && num > 0) {
    setStrength(num);
  } else if (value.trim() === '') {
    setStrength('');
  } else {
    setStrength(0);
  }
};


  return (
    <div>
      <div className="mx-auto max-w-4xl mt-8 p-4 shadow-lg bg-gray-200 dark:bg-gray-800 dark:text-white">
        <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex justify-between items-center mb-4">
            
            {/* is sample */}
            <div className="flex items-center">
              <label htmlFor="isSample" className="dark:text-white" style={{marginRight: '10px' }} >
                Sample:
              </label>
              <input
                type="checkbox"
                id="isSample"
                name="isSample"
                checked={isSample}
                onChange={handleIsSampleChange}
                className="checkbox"
                
              />

                {/* Info indicator */}
                  <div className="ml-2 relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[20px] h-[20px] fill-[#8e8e8e]"
                      viewBox="0 0 512 512"
                      onMouseEnter={() => setShowIsSampleTooltip(true)}
                      onMouseLeave={() => setShowIsSampleTooltip(false)}
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
                    </svg>
                    {showIsSampleTooltip && (
                      <div className="absolute z-10 w-60 p-2 -mt-1 text-sm text-white bg-black rounded-md shadow-lg">
                        Si se activa, el testcase ya no estará oculto. Generalmente, es una buena idea establecer 1 o 2 testcase(s) como ejemplo, ayuda a los usuarios a depurar su código mucho más fácil.
                      </div>
                    )}
                  </div>
            </div>

            {/* Strength */}
            <div className="flex items-center">
              <label htmlFor="strength" className="dark:text-white" style={{marginRight: '10px' }} >
                Strength:
                {!isSample && <span style={requiredAsteriskStyle}>*</span>}
              </label>
              <input
                type="text"
                id="strength"
                name="strength"
                value={strength}
                onChange={handleStrengthChange}
                disabled={isSample}
                className="px-2 py-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
                                
                  {/* Info indicator */}
                  <div className="ml-2 relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[20px] h-[20px] fill-[#8e8e8e]"
                      viewBox="0 0 512 512"
                      onMouseEnter={() => setShowStrengthTooltip(true)}
                      onMouseLeave={() => setShowStrengthTooltip(false)}
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
                    </svg>
                    {showStrengthTooltip && (
                      <div className="absolute z-10 w-60 p-2 -mt-1 text-sm text-white bg-black rounded-md shadow-lg">
                        Fuerza relativa del caso de prueba. Se utiliza para la distribución de la puntuación.
                      </div>
                    )}
                  </div>

              {formErrors.strength && !isSample && (
                <div className="ml-2">
                  {alertIcon}
                </div>
              )}
              {formErrors.strength && (
                <p className="text-red-500 text-xs mt-1">{formErrors.strength}</p>
              )}

              
            </div>
            
          </div>



          {/* inputCode */}
          <div className="mb-6">
            <label htmlFor="problem-statement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Formato de entrada (Input format) <span style={requiredAsteriskStyle}>*</span>
            </label>
            <div className="flex items-start">
              <div className={`flex-grow ${formErrors.input_code ? 'border-red-500' : ''}`}>
                <CodeEditor
                  initialValue={inputCode}
                  //label="Input Code"
                  onChange={(code) => setInputCode(code)}
                />
              </div>
              {formErrors.input_code && (
                <div className="ml-2 mt-2">
                  {alertIcon}
                </div>
              )}
            </div>
            {formErrors.input_code && (
              <p className="text-red-500 text-xs mt-1">{formErrors.input_code}</p>
            )}
          </div>



          {/* outputCode */}
          <div className="mb-6">
            <label htmlFor="problem-statement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Formato de salida (Output format) <span style={requiredAsteriskStyle}>*</span>
            </label>
            <div className="flex items-start">
              <div className={`flex-grow ${formErrors.output_code ? 'border-red-500' : ''}`}>
                <CodeEditor
                  initialValue={outputCode}
                  //label="Input Code"
                  onChange={(code) => setOutputCode(code)}
                />
              </div>
              {formErrors.output_code && (
                <div className="ml-2 mt-2">
                  {alertIcon}
                </div>
              )}
            </div>
            {formErrors.output_code && (
              <p className="text-red-500 text-xs mt-1">{formErrors.output_code}</p>
            )}
          </div>

          
          {/* Explanation */}
          <div className="mb-6">
            <label htmlFor="problem-statement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Explicación
            </label>
            <div className="flex items-start">
              <div className="flex-grow">
                <CodeEditor
                  initialValue={explanation}
                  onChange={(code) => setExplanation(code)}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-4" >
            {inForm && (
              <button
                type="submit"
                className="btn btn-outline btn-info"
              >
                Siguiente

              </button>
            )}                        
          </div>

          <div style={{ paddingTop: '20px' }}>
            {!inForm && (
              <button type="submit" className="btn btn-outline btn-success">
                {testCaseToEdit ? 'Guardar Cambios' : 'Guardar'}
              </button>
            )}
          </div>
            

        </form>
      </div>
    </div>
  );
};

export default TestCaseForm;
import React from 'react'
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import CodeEditor  from '../components/CodeEditor';


const Test = () => {
  return (
    <>
    <NavBar/>
    <div className='mt-4'>
    <CodeEditor/>
    </div>
    
    <Footer/>
    </>
  )
}

export default Test
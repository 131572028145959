import "./App.css";
import {
  Routes,
  Route,
  Outlet,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import Test from "./pages/Test";
import ForgotPassword from "./pages/ForgotPassword";
import Profile from "./pages/Profile";
import ProfessorExerciseView from "./pages/professorProfile/ProfessorExercises";
import ExerciseList from "./pages/professorProfile/ExercisesList";
import BrowseProblems from "./pages/Problems";
import SolvingProblems from "./pages/SolvingProblems";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import RankingPage from "./pages/RankingPage";
import ProblemHistory from "./pages/ProblemHistory";
//Rutas profesor
import ExerciseOptions from './pages/professorProfile/ExerciseOptions';
import ExerciseDetails from './pages/professorProfile/components/ExerciseDetails';
import TestCase from './pages/professorProfile/components/TestCase';
import TestCaseForm from './pages/professorProfile/components/TestCaseForm';
import CodeStub from './pages/professorProfile/components/CodeStub';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setToken } from './features/auth/userSlice';
import { useState } from 'react';

import { useSelector } from "react-redux";
import AulaAuthPage from "./pages/AulaAuth";
import ProfessorRanking from "./pages/professorProfile/ProfessorRanking";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { token } = useSelector(state => state.user);
  useEffect(() => {
    if(!token){
      if(localStorage.getItem('token') && localStorage.getItem('refreshToken')){
        dispatch(setToken({
          token: localStorage.getItem('token'),
          refreshToken: localStorage.getItem('refreshToken')
        }));
      }else{
        navigate('/auth');
      }
    }
  }, [token]);
  //console.log(token);

  return <Outlet />;
};

const AuthRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('accessToken') && searchParams.get('refreshToken')) {
      dispatch(setToken({
        token: searchParams.get('accessToken'),
        refreshToken: searchParams.get('refreshToken')
      }));
      localStorage.setItem('token', searchParams.get('accessToken'));
      localStorage.setItem('refreshToken', searchParams.get('refreshToken'));
      setSearchParams('');
      navigate('/problems/browse');
    } else if (localStorage.getItem('token') && localStorage.getItem('refreshToken')) {
      dispatch(setToken({
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken')
      }));
      navigate('/problems/browse');
    }
  }, [searchParams]);
  return <Outlet />;
};

const GenericRoute = () => {
  return (
    <Outlet />
  );
}

function App() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.id);
  const isAllowed = user && user.rol ? user.rol.includes("ESTUDIANTE") : false;


  useEffect(() => {
    let token = searchParams.get("accessToken");
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", searchParams.get("refreshToken"));
      dispatch(setToken(token));
      setSearchParams({});
    } else {
      token = localStorage.getItem("token");
      if (token) {
        dispatch(setToken(token));
      }
    }
    setLoading(false);
  }, [dispatch, searchParams, setSearchParams]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Routes>
        <Route path="/auth" element={<AuthRoute />}>
          <Route path="register" element={<RegisterPage />} />
          <Route path="" element={<AulaAuthPage />} />
        </Route>
        {/*<Route element={<ProfessorProtectedRoute isAllowed={isAllowed} />}> */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/test" element={<Test />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/professor" element={<ProfessorExerciseView />} />
          <Route path="/section-ranking" element={<ProfessorRanking />} />
          <Route path="/exercises" element={<ExerciseList />} />
          <Route path="/ranking" element={<RankingPage />} />
          {/* <Route path="/history/:user_id" element={<ProblemHistory />} /> */}
          <Route path="/history/:id" element={<ProblemHistory />} />
          <Route path="/exercises/:exerciseId" element={<ExerciseOptions />}>
            <Route path="details" element={<ExerciseDetails />} />
            <Route path="testcases" element={<TestCase />} />
            <Route path="testcases/:testCaseId" element={<TestCaseForm />} />
            <Route path="codestubs" element={<CodeStub />} />
          </Route>
          <Route path="/" element={<GenericRoute />}>
            <Route path="profile/:id" element={<Profile />} />
          </Route>

          <Route path="/problems" element={<GenericRoute />}>
            <Route path="browse" element={<BrowseProblems />} />
            <Route path="solve/:id" element={<SolvingProblems />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Hero from '../../components/Hero';
import { useSelector } from 'react-redux';
const ProfessorExerciseView = () => {
    const {user } = useSelector(state => state); // Lees el store.
    // Mostrar alumnos, ejercicios, etc
    return (
            
                <div className="overflow-x-auto">
                    <NavBar/>
                    <div className="container mx-auto p-4">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Curso</th>
                                <th>Favorite Color</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* row 1 */}
                            <tr>
                                <th>1</th>
                                <td>Cy Ganderton</td>
                                <td>Quality Control Specialist</td>
                                <td>Blue</td>
                            </tr>
                            {/* row 2 */}
                            <tr>
                                <th>2</th>
                                <td>Hart Hagerty</td>
                                <td>Desktop Support Technician</td>
                                <td>Purple</td>
                            </tr>
                            {/* row 3 */}
                            <tr>
                                <th>3</th>
                                <td>Brice Swyre</td>
                                <td>Tax Accountant</td>
                                <td>Red</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
                 
    );   
    
};

export default ProfessorExerciseView;
import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";

function ExerciseCard({ id, maxScore, title, difficulty, score, subject, property }) {
  var pass = false;
  var stylePass = "text-red-500";
  if (score) {
    pass = true;
    stylePass = "text-green-500";
  }

  return (
    <div className="w-full max-w-[50rem] mx-auto p-3">
      <div className="bg-gray-300 dark:bg-gray-800 shadow-xl rounded-lg flex flex-col md:flex-row items-start">
        <div className="md:w-1/2 px-3 py-2 flex flex-col">
          <NavLink to={`/problems/solve/${id}`} className="text-gray-800 dark:text-gray-400 font-bold text-lg mb-2">
            <div className="flex items-center gap-1">
              <span>{title}</span>
              {pass ? <BsCheckCircleFill className="text-green-500" /> : <BsFillXCircleFill className="text-red-600" />}
            </div>
          </NavLink>
          {property && <span className="text-gray-400 mb-2">by: {property}</span>}
          <div className="flex flex-col md:flex-grow px-3 mt-3 items-start">
            <h3 className="text-gray-800 dark:text-gray-400 mb-2">Asignatura: {subject}</h3>
            <h3 className="text-gray-800 dark:text-gray-400 mb-2">Dificultad: {difficulty}</h3>
            <h3 className="text-gray-800 dark:text-gray-400 mb-2">Puntaje máximo: {maxScore}</h3>
          </div>
        </div>
        <div className="my-2 md:py-2 self-start md:self-center">
          <Link to={`/problems/solve/${id}`}> <button className="btn btn-primary w-full md:w-auto md:h-[2rem]">Intentar de nuevo</button></Link>
        </div>
      </div>
    </div>
  );
}

export default ExerciseCard;
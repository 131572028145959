import React from 'react'

const Hero = () => {
  return (
    <>
        {/*<div className="hero min-h-screen bg-neutral text-neutral-content">*/}
    <div className="hero min-h-screen bg-base-200">
  <div className="hero-content flex-col lg:flex-row">
    <img src="https://daisyui.com/images/stock/photo-1635805737707-575885ab0820.jpg" alt="foto" className="max-w-sm rounded-lg shadow-2xl" />
    <div>
      <h1 className="text-5xl font-bold">En preparación!!</h1>
      <p className="py-6">Bienvenido a la página desarrollada por el proyecto de investigación. Próximamente traeremos novedades.</p>
      <button className="btn btn-primary">Iniciar aventura</button>
    </div>
  </div>
</div>
    
    
    
    </>
  )
}

export default Hero
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageLight from '../assets/img/create-account-office.jpeg';
import ImageDark from '../assets/img/login-office-dark.jpeg';

const Login = () => {
  // Estados para email y contraseña
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Manejador del envío del formulario
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (email === 'prueba@usm.cl' && password === '12345') {
      // Redirigir al usuario al perfil
      navigate('/profile');
    } else {
      alert('Error al iniciar sesión');
    }
  };

  return (
    <>
      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-32 md:h-auto md:w-1/2">
              <img
                aria-hidden="true"
                className="object-cover w-full h-full dark:hidden"
                src={ImageLight}
                alt="Office"
              />
              <img
                aria-hidden="true"
                className="hidden object-cover w-full h-full dark:block"
                src={ImageDark}
                alt="Office"
              />
            </div>
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Iniciar sesión</h1>
                <form onSubmit={handleSubmit}>
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input
                    className="input input-bordered"
                    type="email"
                    placeholder="nombre.apellido@usm.cl"
                    name='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label className="label">
                    <span className="label-text">Contraseña</span>
                  </label>
                  <input
                    className="input input-bordered"
                    type="password"
                    placeholder="***************"
                    name='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label className="label">
                    <a href="#" className="label-text-alt link link-hover">Olvidaste la contraseña?</a>
                  </label>

                  <div className="form-control mt-6">
                    <button type="submit" className="btn btn-outline btn-success">Iniciar sesión</button>
                  </div>
                </form>
                <hr className="my-8" />
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

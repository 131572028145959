import React, { useState, useEffect, useRef} from 'react';
import MarkdownEditor from './MarkdownEditor';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import TagsInput from './TagsInput';
import api from '../../../features/auth/api';
import {jwtDecode} from 'jwt-decode';


const ExerciseForm = ({onNewExercise, exerciseToEdit, onExerciseUpdated, onNextStep})=>{
    const [problemName, setProblemName] = useState('');
    const [problemStatement, setProblemStatement] = useState('');
    const [inputFormat, setInputFormat] = useState('');
    const [constraints, setConstraints] = useState('');
    const [outputFormat, setOutputFormat] = useState('');
    const user_subject = jwtDecode(localStorage.getItem('token')).user_data.subject.subject;
    //const user_subject = 'TEL-101'
    const [subject, setSubject] = useState(user_subject);
    const [language, setLanguage] = useState('Español');
    const [programmingLanguage, setProgrammingLanguage] = useState('Python');
    const [difficulty, setDifficulty] = useState('Fácil');
    const [binary, setBinary] = useState(false);
    const [score, setScore] = useState(0);

    const successMessageRef = useRef(null);
    const [successMessage, setSuccessMessage] = useState('');

    const isEditing = !!exerciseToEdit;

    const [exercises, setExercises] = useState([]);
    const [selectedExercise, setSelectedExercise] = useState(null); //"dependencies"

    //tags
    const [tags, setTags] = useState([]);
    const [newTags, setNewTags] = useState([]);

    const [showTooltip, setShowTooltip] = useState(false);


    const normalizeExerciseData = (data) => {
      return {
        title: data.title,
        subject: data.subject,
        problem_statement: data.files_data?.problem_statement || data.problem_statement,
        input_format: data.input_format,
        constraints: data.constraints,
        output_format: data.output_format,
        language: data.language || 'Español',
        programming_language: data.programming_language || 'Python',
        difficulty: data.difficulty || 'Fácil',
        binary: data.binary || false,
        score: data.score || 0,
        contents: data.contents ? data.contents.split(',') : [],
        exercise_dependency: data.exercise_dependency, 
      };
    };
    

    useEffect(() => {
      
      if (exerciseToEdit && exercises.length > 0) {
        const normalizedData = normalizeExerciseData(exerciseToEdit);
        setProblemName(normalizedData.title); //problemName
        setSubject(normalizedData.subject);
        setProblemStatement(normalizedData.problem_statement);
        setInputFormat(normalizedData.input_format);
        setConstraints(normalizedData.constraints);
        setOutputFormat(normalizedData.output_format);
        setLanguage(normalizedData.language || 'Español');
        setProgrammingLanguage(normalizedData.programming_language || 'Python');
        const initialTags = normalizedData.contents;
        setTags(initialTags);
        setDifficulty(normalizedData.difficulty || 'Fácil');
        setBinary(normalizedData.binary || false);
        setScore(normalizedData.score || 0);
        const exerciseToSelect = formattedExercises.find(e => e.id === exerciseToEdit.exercise_dependency);
        setSelectedExercise(exerciseToSelect || null);
        //setSelectedExercise(exercises.find(e => e.exercise_id === normalizedData.exercise_dependency));
      }
    }, [exerciseToEdit, exercises]);

    //Para encontrar los ejercicios del dependencies
    useEffect(() => {
      const getExercises = async () => {
        try {
          //const user_subject = jwtDecode(localStorage.getItem('token')).user_data.subject;
          //const user_subject = 'TEL-101'
          const url = `https://backend.teloprogramo.cl/api/exercise/list/${user_subject}`
          const response = await api.get(url);
          if (response.status != 200) throw new Error('Failed to fetch');
          const { results }  = await response.data;
          setExercises(results);
        } catch (error) {
          console.log(error);
        }
      };
      getExercises();
    }, []);

    const handleSaveChanges = async (e) =>{
        e.preventDefault();
        const exerciseData = {
          title: problemName,
          subject,
          problem_statement : problemStatement ,
          input_format : inputFormat,
          constraints,
          output_format: outputFormat,
          language,
          programming_language : programmingLanguage,
          difficulty,
          contents: tags.join(','),
          //createdBy,
          binary,
          score,
          exercise_dependency: selectedExercise ? selectedExercise.id : null,
        };
        
        if (!isFormValid(exerciseData)){
         
          return;
        }
        const url = exerciseToEdit ? `https://backend.teloprogramo.cl/api/exercise/update/${exerciseToEdit.exercise_id}/`: 'https://backend.teloprogramo.cl/api/exercise/create'; 
        const method = exerciseToEdit ? 'PUT' : 'POST'; 
        try {
            const response = await api({
              method,
              url,
              data: exerciseData
            }); 
            if (response.status) {
              const result = await response.data;
              if (exerciseToEdit) {
                onExerciseUpdated(exerciseData);
                setSuccessMessage('Changes successfully saved!');
                scrollToSuccessMessage();
              } else {
                onNewExercise(result);
                onNextStep();
              }
              //Para reiniciar el estado 
/*               setProblemName('');
              setSubject('');
              setProblemStatement('');
              setInputFormat('');
              setConstraints('');
              setOutputFormat(''); */
              //navigate('/exercises');
            } else {
              throw new Error('Submission failed');
            }
          } catch (error) {
            console.error('Error during submission:', error);
          }

    };

    useEffect(() => {
      if (successMessage) {
        const timer = setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
        return () => clearTimeout(timer);
      }
    }, [successMessage]);

  const scrollToSuccessMessage = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  //Validar los campos del formulario
  const [formErrors, setFormErrors] = useState({});
  const isFormValid = (formData) => {
    const errors = {};
    for (const key in formData) {
      if (key !== 'exercise_dependency'){
        if (key !== 'exercise_dependency' && (formData[key] === '' || formData[key] === undefined || formData[key] === null )) {
          errors[key] = "Requerido";
        }
        if (key === 'contents' && formData[key].length === 0) {
          errors[key] = "Se debe añadir al menos un Tag";
        }
      }
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
    //return true;
  };

//NUevo borrar
const [searchTerm, setSearchTerm] = useState('');
const handleSearch = (event) => {
  const term = event.target.value;
  setSearchTerm(term);
  //onSearch(term);
};

const [exerciseFilter, setExerciseFilter] = useState([]);
useEffect(() => {
  const filtered = exercises.filter((exercise) =>
  exercise.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
);
setExerciseFilter(filtered);
}, [exercises, searchTerm]);

//tags
const handleAddTag = (newTag) => {
  //console.log("newtags", newTag, "los actuales", tags)
/*   if (!tags.includes(newTag)) {
    setTags([...tags, newTag]);
  } */
  setTags(newTag);
};

const handleRemoveTag = (indexToRemove) => {
  setTags(tags.filter((_, index) => index !== indexToRemove));
};

useEffect(() => {
  const getAllTags = async () => {
    try {
      const url = `https://backend.teloprogramo.cl/api/subject/contents/${user_subject}`;
      const response = await api.get(url);
      if (response.status != 200) throw new Error("Failed to fetch");
      const results  = await response.data.contents;
      setNewTags(results.split(","));
    } catch (error) {
      console.log(error);
    }
  };
  getAllTags();
}, []);



//Combo box

const formattedExercises = exercises.map(exercise => ({
  label: exercise.title,
  id: exercise.exercise_id,
}));

//Requerimientos
// *  rojo
const requiredAsteriskStyle = { color: 'red', marginLeft: '2px' };

//alert icon
const alertIcon = ( 
  <svg className="w-[27px] h-[27px] fill-[#f24545]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
</svg>
);


const handleAutocompleteChange = (event, newValue) => {
  setSelectedExercise(newValue);
};

return (
      <div className="max-w-4xl mx-auto mt-8">
          {successMessage && (
              <div className="bg-green-500 text-white p-4 mb-4 rounded">
                  {successMessage}
              </div> 
          )}
          <div ref={successMessageRef}></div>
          {/* <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-800 rounded shadow-lg"> */}
          <form onSubmit={handleSaveChanges} className="space-y-6">
              
            <div className="flex flex-wrap items-center justify-between gap-4 mb-6 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white ">
             
              <div className="grid grid-cols-3 gap-4">
                 {/*Binary*/}
                <div className="flex items-center mr-4">
                  <input
                    className="checkbox checkbox-primary"
                    type="checkbox"
                    checked={binary}
                    onChange={() => setBinary(!binary)}
                    id="checkboxDefault" />
                  
                  <label
                    className="label cursor-pointer"
                    htmlFor="checkboxDefault">
                    Binario <span style={requiredAsteriskStyle}>*</span>
                  </label>
                  
                  {/* Info indicator */}
                  <div className="ml-2 relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[20px] h-[20px] fill-[#8e8e8e]"
                      viewBox="0 0 512 512"
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
                    </svg>
                    {showTooltip && (
                      <div className="absolute z-10 w-60 p-2 -mt-1 text-sm text-white bg-black rounded-md shadow-lg">
                        "Si está activado, el usuario obtendrá una puntuación distinta de cero solo cuando su envío pase todos los casos de prueba. En caso contrario, obtendrá puntaje parcial por cada caso de uso correcto."
                      </div>
                    )}
                  </div>

                </div>

                {/* Puntaje */}
                <div className="flex items-center">
                  <label
                    className="inline-block pr-[0.15rem] hover:cursor-pointer"
                    htmlFor="scoreInput"
                    style={{marginRight: '10px' }} 
                    >
                    Puntaje:
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    id="scoreInput"
                    value={score}
                    onChange={(e) => setScore(Number(e.target.value))}
                    className="input input-bordered input-info w-full h-7 max-w-xs" />
                    <span style={requiredAsteriskStyle}>*</span>
                </div>
                
              </div>
  
              <div className="grid grid-cols-3 gap-5">
                  <div>
                    {/*Language*/}
                    <div className="mb-4">
                        <label className="dark:text-white" style={{marginBottom: '10px' }} >
                          Idioma: 
                          <span style={requiredAsteriskStyle}>*</span>
                        </label>
                        <div className="relative inline-block text-left w-full">
                          <select
                              value={language}
                              onChange={(e) => setLanguage(e.target.value)}
                              className="select select-bordered select-sm w-full max-w-xs"
                          >
                              <option value="" disabled hidden> Select Language</option>
                              <option value="Espanol">Español</option>
                              <option value="English">English</option>
                          </select>

                        </div>
                    </div>
                  </div>

                  <div>
                    {/*Progeammin language*/}
                    <div className="mb-4">
                        <label className="dark:text-white" style={{marginBottom: '10px' }}>
                          Lenguaje de programación:
                          <span style={requiredAsteriskStyle}>*</span>
                        </label>
                        <div className="relative inline-block text-left w-full">
                        <select
                            value={programmingLanguage}
                            onChange={(e) => setProgrammingLanguage(e.target.value)}
                            className="select select-bordered select-sm w-full max-w-xs"
                        >
                            <option disabled hidden> Seleccione el lenguaje de programación</option>
                            <option >Python</option>
                            <option >C</option>
                            <option >C++</option>
                            <option >Java</option>
                        </select>

                        </div>
                    </div>   

                  </div>

                  <div>
                    {/*Dificultad*/}
                    <div className="mb-4">
                        <label className="dark:text-white" style={{marginBottom: '10px' }}>
                          Dificultad:
                          <span style={requiredAsteriskStyle}>*</span>
                        </label>
                        <div className="relative inline-block text-left w-full">
                        <select
                            value={difficulty}
                            onChange={(e) => setDifficulty(e.target.value)}
                            className="select select-bordered select-sm w-full max-w-xs"
                        >
                            <option value="" disabled hidden> Select difficulty</option>
                            <option value="Facil">Fácil</option>
                            <option value="Medio">Medio</option>
                            <option value="Dificil">Dificil</option>
                        </select>

                        </div>
                    </div> 
                  </div>

              </div>
              
              </div>

              


                  {/*Input tags*/}
                  <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white ">
                    <label htmlFor="tags-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Tags <span style={requiredAsteriskStyle}>*</span>
                    </label>
                    <TagsInput existingTags={tags} onTagsChange={handleAddTag} suggestions={newTags} />

                    {formErrors.contents && (
                          <div className="ml-2">
                            {alertIcon}
                            <p className="text-red-500 text-xs mt-1">{formErrors.contents}</p>
                          </div>
                        )}
                  </div>


                  <div>
                    {/*Problem Name*/}
                    <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white ">
                      <label htmlFor="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Nombre del problema <span style={requiredAsteriskStyle}>*</span>
                      </label>
                      
                      <div className="flex items-center">
                        <input 
                          type="text" 
                          id="default-input" 
                          value={problemName}
                          onChange={(e) => setProblemName(e.target.value)}
                          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:focus:ring-blue-500 ${formErrors.title ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {formErrors.title && (
                          <div className="ml-2">
                            {alertIcon}
                          </div>
                        )}
                      </div>
                      {formErrors.title && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.title}</p>
                      )}
                    </div>

                    {/*Dependencies*/} {/*Combo box*/}
                    <div className='mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white '>
                      <label htmlFor="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" style={{paddingBottom: '20px' }}>
                        Dependencias 
                      </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedExercise}
                        options={formattedExercises}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                        sx={{ width: 300 }}
                        onChange={handleAutocompleteChange}
                        renderInput={(params) => <TextField {...params} label="Ejercicios" />}
                      />
                    </div>


                    {/*Subject*/}
                    <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white ">
                      <label htmlFor="subject-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Asignatura <span style={requiredAsteriskStyle}>*</span>
                      </label>
                      
                      <div className="flex items-center">
                        <input 
                          type="text" 
                          id="subject-input" 
                          value={subject}
                          //onChange={(e) => setSubject(e.target.value)}
                          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:focus:ring-blue-500 ${formErrors.subject ? 'border-red-500' : 'border-gray-300'}`}
                          disabled
                        />
                        {formErrors.subject && (
                          <div className="ml-2">
                            {alertIcon}
                          </div>
                        )}
                      </div>
                      {formErrors.subject && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.subject}</p>
                      )}
                    </div>

                      {/* Problem Statement */}
                      <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white ">
                        <label htmlFor="problem-statement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Enunciado <span style={requiredAsteriskStyle}>*</span>
                        </label>
                        <div className="overflow-x-auto">
                          <div className={`flex-grow ${formErrors.problem_statement ? 'border-red-500' : ''}`}>
                            <MarkdownEditor
                              id="problemStatement"
                              value={problemStatement}
                              onChange={(text) => setProblemStatement(text)}
                            />
                          </div>
                          {formErrors.problem_statement && (
                            <div className="ml-2 mt-2">
                              {alertIcon}
                            </div>
                          )}
                        </div>
                        {formErrors.problem_statement && (
                          <p className="text-red-500 text-xs mt-1">{formErrors.problem_statement}</p>
                        )}
                      </div>

                      {/* Input format */}
                      <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white ">
                        <label htmlFor="problem-statement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Formato de entrada (Input format) <span style={requiredAsteriskStyle}>*</span>
                        </label>
                        <div className="overflow-x-auto">
                          <div className={`flex-grow ${formErrors.input_format ? 'border-red-500' : ''}`}>
                            <MarkdownEditor
                              id="inputFormat"
                              value={inputFormat}
                              onChange={(code) => setInputFormat(code)}
                            />
                          </div>
                          {formErrors.input_format && (
                            <div className="ml-2 mt-2">
                              {alertIcon}
                            </div>
                          )}
                        </div>
                        {formErrors.input_format && (
                          <p className="text-red-500 text-xs mt-1">{formErrors.input_format}</p>
                        )}
                      </div>

                      {/* constraints */}
                      <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white ">
                        <label htmlFor="problem-statement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Restricciones (constraints) <span style={requiredAsteriskStyle}>*</span>
                        </label>
                        <div className="overflow-x-auto">
                          <div className={`flex-grow ${formErrors.constraints ? 'border-red-500' : ''}`}>
                            <MarkdownEditor
                              id="constraints"
                              value={constraints}
                              onChange={(text) => setConstraints(text)}
                            />
                          </div>
                          {formErrors.constraints && (
                            <div className="ml-2 mt-2">
                              {alertIcon}
                            </div>
                          )}
                        </div>
                        {formErrors.constraints && (
                          <p className="text-red-500 text-xs mt-1">{formErrors.constraints}</p>
                        )}
                      </div>
                      


                      {/* output format */}
                      <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-200 dark:bg-gray-800 p-4 dark:text-white " >
                        <label htmlFor="problem-statement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Formato de salida (output format) <span style={requiredAsteriskStyle}>*</span>
                        </label>
                        <div className="overflow-x-auto">
                          <div className={`flex-grow ${formErrors.output_format ? 'border-red-500' : ''}`}>
                            <MarkdownEditor
                              id="outputFormat"
                              value={outputFormat}
                              onChange={(code) => setOutputFormat(code)}
                            />
                          </div>
                          {formErrors.output_format && (
                            <div className="ml-2 mt-2">
                              {alertIcon}
                            </div>
                          )}
                        </div>
                        {formErrors.output_format && (
                          <p className="text-red-500 text-xs mt-1">{formErrors.output_format}</p>
                        )}
                      </div>
                          
                      <div className="flex justify-end mt-4" >
                        {!isEditing && (
                          <button
                            type="submit"
                            className="btn btn-outline btn-info"
                          >
                            Siguiente

                          </button>
                        )}                        
                      </div>

                          <div style={{paddingTop: '20px' }}>
                            {isEditing && (
                              <button
                                type="submit"
                                //className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                className="btn btn-outline btn-success"
                              >
                                Guardar Cambios
                              </button>
                            )}
                          </div>


                  </div>
              </form>
          
      </div>
);
};

export default ExerciseForm;
import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState = {
  user_id : null,
  token: null,
  refreshToken: null,
  name: null,
  rol: null,
  institution: null,
  campus: null,
  email: null,
  picture: null,
  section: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action) => {
      const {accessToken} = action.payload;
      const {refreshToken} = action.payload;
      state.token = accessToken;
      if (accessToken) {
        const decoded = jwtDecode(accessToken);
        const userData = decoded.user_data;
        state.user_id = userData.user_id;
        state.name = userData.name;
        state.rol = userData.roles ? userData.roles[0] : null;
        state.institution = userData.institution;
        state.campus = userData.campus;
        state.section = userData.section;
        state.picture = userData.picture;
      }
    },
  },
});

export const { setToken } = userSlice.actions;

export default userSlice.reducer;
import React, {useEffect, useState} from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { NavLink } from 'react-router-dom';
import api from '../../features/auth/api';

const AngleRight = () => (
  <svg
    className="w-4 h-4 text-gray-300 inline-block"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    />
  </svg>
);

const SubNavBar = ({ selectedExercise }) => {
  return (
    <div className="">
      <div className="container mx-auto flex items-center justify-between mb-2">
        <div className="text-sm breadcrumbs">
          <ul>
{/*             <li>
              <Link to="/">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path></svg>
                Home
              </Link>
            </li> */}
            <li>
              <Link to="/exercises">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path></svg>
                Manage Challenge
              </Link>
            </li>
            <li>
                {selectedExercise}
            </li>
          </ul>
        </div>
      </div>
      <hr className="border-gray-700 my-0" />

      <div role="tablist" className="tabs tabs-bordered">
        <NavLink to={`details`} role="tab" className="tab" activeClassName="tab-active">
          Detalles del Ejercicio
        </NavLink>
        <NavLink to={`testcases`} role="tab" className="tab" activeClassName="tab-active">
          Casos de Prueba
        </NavLink>
        <NavLink to={`codestubs`} role="tab" className="tab" activeClassName="tab-active">
          Code Stubs
        </NavLink>
      </div>
    </div>
  );
};



const ExerciseOptions = () => {
  const [selectedExercise, setSelectedExercise] = useState('');
  const { exerciseId } = useParams();

  useEffect(() => {
    const fetchExercise = async () => {
      try {
        const url = `https://backend.teloprogramo.cl/api/exercise/${exerciseId}`
        const response = await api.get(url);
        if (response.status !== 200) {
          throw new Error('Failed to fetch exercise');
        }
        const data = await response.data;
        setSelectedExercise(data?.title);
      } catch (error) {
        console.error('Error fetching exercise:', error);
      }
    };
   

    fetchExercise();
  }, [exerciseId]);

  return (
    <div>
      <NavBar />
      <div className='container mx-auto p-4'>
        <SubNavBar selectedExercise={selectedExercise}/>
        
      </div>
      
      <div className="container mx-auto p-4">
        <Outlet />
      </div>
    </div>
  );
};

export default ExerciseOptions;

import React, { useEffect, useState } from 'react';
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import api from '../../features/auth/api';
import {jwtDecode} from 'jwt-decode';
import { CSVLink } from "react-csv"; 

const ProfessorRanking = () => {
    const [sectionRanking, setSectionRanking] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const subject = jwtDecode(localStorage.getItem('token')).user_data.subject.subject;
    //const section = jwtDecode(localStorage.getItem('token')).user_data.section;
    const [sections, setSections] = useState();
    const [currentSection, setCurrentSection] = useState();
    const professor_name = jwtDecode(localStorage.getItem('token')).user_data.name;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    //Paginación
    const studentsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const [filteredRanking, setFilteredRanking] = useState([]);
    

    const probando = [200, 201]

    useEffect(() => {
      const user_sections = jwtDecode(localStorage.getItem('token')).user_data.subject.sections;
      setSections(user_sections);
      if (user_sections.length > 0) {
          setCurrentSection(user_sections[0]);
      }
    }, []);

    useEffect(() => {
      if (currentSection) {
          const getStudentsRanking = async () => {
              try {
                  const url = `https://backend.teloprogramo.cl/api/exercise/ranking/${subject}/${currentSection}`;
                  const response = await api.get(url);
                  if (response.status !== 200) throw new Error('Failed to fetch');
                  const results = await response.data;
                  setSectionRanking(results);
                  console.log("aca", results)
              } catch (error) {
                  console.error(error);
              }
          };
          getStudentsRanking();
      }
  }, [currentSection]);
  

  useEffect(() => {
    const filteredResults = sectionRanking.filter(data =>
        data.user_details.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRanking(filteredResults);

    setCsvData(filteredResults.map(data => ({
        Estudiante: data.user_details.name,
        Facil: Math.round(data.difficulty_success_rates.Fácil),
        Medio: Math.round(data.difficulty_success_rates.Medio),
        Dificil: Math.round(data.difficulty_success_rates.Dificil),
        Intentos_correctos: data.correct_attempts,
        Intentos_totales: data.total_attempts,
        Resueltos: data.exercises_completed,
        Tasa_de_exito: Math.round(data.success_rate),
        Puntaje_total: data.total_score,
        Top_contenidos: getTopContentString(data.content_success_rates)
    })));
}, [sectionRanking, searchTerm]);
  



  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (section) => {
      setCurrentSection(section);
      setDropdownOpen(false);
  };

  //Badge del top contenidos
  const getTopContentBadges = (contentSuccessRates) => {
    const sortedContent = Object.entries(contentSuccessRates)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5); // Tomamos los primeros 5
    return sortedContent.map((content, index) => (
      <div key={index} className="badge badge-accent badge-outline">{content[0]}</div>
    ));
  };

  //TRansformar el badges a string
  const getTopContentString = (contentSuccessRates) => {
    const sortedContent = Object.entries(contentSuccessRates)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5); 
    return sortedContent.map(entry => entry[0]).join(', ');
  };

  //Barra de busqueda
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
};

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastExercise = currentPage * studentsPerPage;
  const indexOfFirstExercise = indexOfLastExercise - studentsPerPage;
  const currentRanking = filteredRanking.slice(indexOfFirstExercise, indexOfLastExercise);
  const pageCount = Math.ceil(filteredRanking.length / studentsPerPage);


  return (
      <div className="flex flex-col h-screen">
          <NavBar />

          <div className="flex justify-between items-center p-4">
                  <h1 className="text-xl font-semibold">
                    Ranking {subject} Profesor {professor_name} | Paralelos: {currentSection}
                  </h1>

                  <div className="dropdown" onClick={handleDropdownClick}>
                      <div tabIndex={0} className="btn m-1">
                          Paralelo: {currentSection} <span className="ml-2">▼</span>
                      </div>
                      {dropdownOpen && (
                          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                            {/* Aca cambiar el probando por sections */}
                              {sections.map((section, index) => (
                                  <li key={index} onClick={() => handleItemClick(section)}>
                                      <a>{section}</a>
                                  </li>
                              ))}
                          </ul>
                      )}
                  </div>


                  <CSVLink
                      data={csvData}
                      filename={`ranking-profesores_${currentSection}.csv`}
                      className="btn btn-outline btn-primary"
                      separator=';'
                      target="_blank"
                  >
                      Exportar CSV
                  </CSVLink>
              </div>


          <div className="flex items-start">
            <label className="input input-bordered flex items-center gap-2 m-4 search-container" >
              <input 
                type="search" 
                className="grow" 
                placeholder="Search" 
                fill = "transparent"
                style={{ 
                  flexGrow: 1, 
                  padding: '0.5rem', 
                  border: 'none', 
                  outline: 'none',
                  backgroundColor: 'transparent', 
                  color: 'inherit', 
                }} 
                onChange={handleSearch}
              />
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 16 16" 
                fill="currentColor" 
                className="w-4 h-4 opacity-70 search-icon"
              >
                <path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" />
              </svg>
            </label>
          </div>


          <div className="flex-grow">
              <div className="overflow-x-auto ">
                  <table className="table table-zebra w-full">
                      {/* head */}
                      <thead>
                          <tr>
                              <th></th>
                              <th>Estudiante</th>
                              <th>Fácil</th>
                              <th>Medio</th>
                              <th>Dificil</th>
                              <th>Intentos correctos</th>
                              <th>Intentos Totales</th>
                              <th>Resueltos</th>
                              <th>Tasa de Exito</th>
                              <th>Puntaje Total</th>
                              <th>Top contenidos</th>
                          </tr>
                      </thead>
                      <tbody>
                        {/* filteredRanking */}
                          {currentRanking.map((data, index) => (
                              <tr key={index}>
                                  <th>{index+1}</th>
                                  <div className="flex items-center"> 
                                  <img 
                                      src={`https://ui-avatars.com/api/?name=${encodeURIComponent(data.user_details.name)}&background=random&color=fff&length=2&bold=true&size=128`}
                                      alt={`Avatar de ${data.user_details.name}`}
                                      className="inline-block h-8 w-8 rounded-full mr-2"
                                  />
                                    {data.user_details.name}
                                  </div>
                                  <td className={data.difficulty_success_rates.Fácil < 50 ? "text-red-500" : "text-green-500"}> {Math.round(data.difficulty_success_rates.Fácil)} %</td>
                                  <td className={data.difficulty_success_rates.Medio < 50 ? "text-red-500" : "text-green-500"}>{Math.round(data.difficulty_success_rates.Medio)} %</td>
                                  <td className={data.difficulty_success_rates.Dificil < 50 ? "text-red-500" : "text-green-500"}>{Math.round(data.difficulty_success_rates.Dificil)} %</td>
                                  <td>{data.correct_attempts}</td>
                                  <td>{data.total_attempts}</td>
                                  <td>{data.exercises_completed}</td>
                                  <td className={data.success_rate < 50 ? "text-red-500" : "text-green-500"}>{Math.round(data.success_rate)} %</td>
                                  <td>{data.total_score}</td>
                                  <td>{getTopContentBadges(data.content_success_rates)}</td>
                              </tr>
                          ))}
                          {Array(10 - currentRanking.length).fill().map((_, index) => (
                            <tr key={`placeholder-${index}`} style={{ height: '3rem', backgroundColor: 'transparent', border: 'none'}}>
                              <td colSpan="11">&nbsp;</td> 
                            </tr>
                          ))}
                      </tbody>
                  </table>
              </div>

            {/*Paginacion*/}
            <div className="flex justify-center mt-4">
              {[...Array(pageCount).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`join-item btn btn-square ${
                    currentPage === number + 1 ? "btn-active" : ""
                  }`}
                  aria-label={number + 1}
                >
                  {number + 1}
                </button>
              ))}
            </div>

          </div>

          <Footer />
      </div>
  );

}

export default ProfessorRanking;


import React, { useState, useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { monokai } from "@uiw/codemirror-theme-monokai";
import { loadLanguage } from "@uiw/codemirror-extensions-langs";
import api from '../features/auth/api';


function CodeEditor({code, readOnly = false}) {
  const API_KEY = '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';
  const [editorValue, setEditorValue] = useState(code || '');
  const [outputText, setOutputText] = useState('');
  const [showResponse, setShowResponse] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);
  const [helpText, setHelpText] = useState("");
  const [helpRequestsCount, setHelpRequestsCount] = useState(0);

  const handleEditorChange = useCallback((value, viewUpdate) => {
    setEditorValue(value);
  }, []);

  async function getChatCompletion() {
    try {
      const messages = [
        { role: "system", content: "Tú eres un analizador de código." },
        {
          role: "user",
          content:
            'Tengo el siguiente código en python y quiero que me identifiques errores si es que existen. De ser así, dime la línea del error, pero no lo arregles. La estructura de la respuesta quiero que sea la siguiente: "Explicación del error"\n explicas el error y luego abajo: "Ejemplo de corrección"\n y brindas un ejemplo. Este es el código original a analizar:\n' +
            editorValue,
        },
      ];

      if (needHelp) {
        messages.push({ role: "user", content: helpText });
      }

      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + API_KEY,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: messages,
        }),
      });

      const data = await res.json();
      setOutputText(data.choices[0].message.content);
      setShowResponse(true);

      if (needHelp) {
        setHelpRequestsCount(helpRequestsCount + 1);
      }
    } catch (error) {
      console.error("Error al obtener la respuesta de la API:", error);
    }
  }
  async function sentToApi(feedback_inicial) {
    try {
      const params = new URLSearchParams({
        email: "admin@usm.cl",
        contrasena: "admin",
      });
      const res = await api.get(`/usuario/token/?${params}`);
      const data = await res.json();

      const body = {
        id_ejercicio: 1,
        id_usuario: 1,
        tiempo: "10:00:00", //debe haber algun contador
        codigo: "print('Hello World')",
        feedback_inicial: feedback_inicial,
      };
      const resIntento = await api.post(
        "/ejercicio/intento",
        JSON.stringify(body)
      );
      const intento_data = await resIntento.json();
      //console.log(intento_data);
    } catch (err) {
      console.error("Error al obtener el token:", err);
    }
  }
  const handleGenerateButtonClick = async () => {
    setShowResponse(false); // Ocultar la respuesta antes de obtener una nueva
    const feedback_inicial = await getChatCompletion();
    await sentToApi(feedback_inicial);
    setShowResponse(false);
    if (needHelp && helpRequestsCount >= 3) {
      alert("Ya has agotado tus 3 solicitudes de ayuda.");
      return;
    }
    await getChatCompletion();
  };

  const handleHelpButtonClick = () => {
    if (helpRequestsCount >= 3) {
      alert("Ya has agotado tus 3 solicitudes de ayuda.");
      return;
    }
    setNeedHelp(!needHelp);
  };

  /*
  const resetHelpRequestsCount = () => {
    setHelpRequestsCount(0);
  };
*/
  return (
    <div className="flex flex-col items-center p-5 mt-4 w-full h-full">
      {" "}
      {/* Ajustes para que tome todo el espacio disponible */}
      <CodeMirror
        value={editorValue}
        placeholder={"---pega tu código aquí---"}
        width="60vw"
        height="60vh"
        theme={monokai}
        extensions={loadLanguage("python")}
        onChange={handleEditorChange}
        className="border border-gray-300 rounded-md"
      />
      {
      !readOnly &&
        <div className="flex space-x-4 mt-2 ">
          <button 
            className="btn btn-outline btn-success"
            onClick={handleGenerateButtonClick}>
            Ejecutar
          </button>
          <button 
            className="btn btn-outline btn-warning"
            onClick={handleHelpButtonClick}>
            Necesitas ayuda
          </button>
      </div>
      }

      {showResponse && (
        <div className="text-gray-500 mt-5 p-5 border border-gray-300 overflow-auto w-full max-h-[calc(100vh-100px)]">
          <h2 className="text-lg text-gray-700 font-sans">Resultado:</h2>
          <p>
            <code>{outputText}</code>
          </p>
        </div>
      )}
      {needHelp && (
        <div className="mt-5 w-[60vw] dark:bg-gray-800 bg-white p-5 rounded-lg shadow-md">
          <h2 className="text-lg mb-2">Tienes una pregunta adicional:</h2>
          <textarea
            value={helpText}
            onChange={(e) => setHelpText(e.target.value)}
            className="resize-none w-full h-24 mb-2 p-2 text-sm border border-gray-300 rounded-md"
          />
          <button
            onClick={handleGenerateButtonClick}
            className="bg-green-600 text-white px-5 py-2 rounded-md cursor-pointer text-sm"
          >
            Enviar Pregunta
          </button>
        </div>
      )}
    </div>
  );
}

export default CodeEditor;

import React from 'react'
import Footer from '../components/Footer'

const AulaAuthPage = () => {
  return (
    <>
      <h1 className="font-bold text-4xl text-center my-32">
        Por el momento debes ingresar desde AULA.
      </h1>
      <div className="mockup-browser border bg-base-300 w-3/4 mx-auto">
        <div className="mockup-browser-toolbar">
          <div className="input">https://aula.usm.cl/</div>
        </div>
        <div className="flex justify-center px-4 py-16 bg-base-200">
          <button className="btn  m-4 text-xl bg-base-100" onClick={() => window.location.href = 'https://aula.usm.cl/'}>
            Ir a Aula
          </button>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AulaAuthPage
import axios from "axios";
import {jwtDecode} from "jwt-decode";
const instance = axios.create({
  baseURL: "https://backend.teloprogramo.cl/api",
  timeout: 5000,
  headers: { "Content-Type": "application/json", "Accept": "application/json"},
});

const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp < currentTime;
};

const refreshToken = async () => {


  
  try {
    const response = await instance.post("/user/token/refresh/", {
      refresh: localStorage.getItem("refreshToken"),
    });
    //console.log("Refresh token response", response.data);
    localStorage.setItem("token", response.data.access);
    return response.data.access;
  } catch (error) {
    console.error("Error refreshing token", error);
    return null;
  }
};

instance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("token");

    if (isTokenExpired(token)) {
      token = await refreshToken();
    }

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log("Error response", error.message);
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const token = await refreshToken();

      if (token) {
        originalRequest.headers["Authorization"] = `Bearer ${token}`;
        return instance(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ExerciseCard from '../components/CardExercise';
//import ErrorsCalendar from '../components/ErrorsCalendar';
import { FaCoins } from "react-icons/fa6";
import { jwtDecode } from 'jwt-decode';
import { set } from 'date-fns';
import DoughnutChart from '../components/DoughnutChart';
import BarChart from '../components/BarChart';
import MultiSeriesPieChart from '../components/MultiSeriesPieChart';
import RadarChart from '../components/RadarChart';
import { Radar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import api from '../features/auth/api';
import ProfileCard from '../components/profileCard';

const data = {
    labels: ['Listas', 'Ciclos', 'Condicionales', 'Funciones'],
    datasets: [
        {
            label: 'Serie 1', // Nombre de la primera serie
            backgroundColor: 'rgba(0, 123, 255, 0.2)', // Color de fondo de la serie 1
            borderColor: 'rgba(0, 123, 255, 1)', // Color del borde de la serie 1
            data: [10, 20, 8, 10], // Datos de la serie 1
            borderWidth: 1
        },
        {
            label: 'Serie 2', // Nombre de la primera serie
            backgroundColor: 'rgba(255, 0, 0, 0.2)', // Color de fondo de la serie 1
            borderColor: 'rgba(255, 0, 0, 1)', // Color del borde de la serie 1
            data: [5, 10, 20, 3], // Datos de la serie 1
            borderWidth: 1
        },
    ],
};

async function getProblems(userId) {
    const data = await api.get(`https://backend.teloprogramo.cl/api/exercise/exercises-per-user/${userId}`);
    console.log("Problemas:", data.data);
    return data.data;
}

async function getUserInfo(userId) {
    const data = await api.get(`https://backend.teloprogramo.cl/api/user/user_id/${userId}`);
    //console.log("User info:", data.data);
    return data.data;

}

const Profile = () => {
    const url = window.location.href;
            // Crear un objeto URL
            const urlObj = new URL(url);
            // Obtener el pathname (ruta) de la URL
            const pathname = urlObj.pathname;
            // Dividir el pathname en segmentos
            const segments = pathname.split('/');
            // Obtener el último segmento, que en este caso podría ser el ID del usuario
            const userId = segments[segments.length - 1];

useEffect(() => {
            const fetchData = async () => {
            //console.log('subject', subject);
            const userData = await getUserInfo(userId);
            setUserInfo(userData);
        };
        fetchData();
}, []);


    useEffect(() => {
        
        getProblems(userId).then(data => {
            
            setProblems(data);
        }).catch(error => {
            console.error("Error al cargar problemas:", error);
        });
    }, []);


    const [coins, setCoins] = useState(0);
    useEffect(() => {
        async function fetchCoins() {
            
            const coins = await api.get(`https://backend.teloprogramo.cl/api/user/get_coin/${userId}`).then(data => {
                setCoins(data.data.coins);
                
                return data;
            });
        }
        fetchCoins();
    }, []);
    
    useEffect(() => {
        async function fetchUserStatistic() {
            // Obtener la URL completa
            const url = window.location.href;
            // Crear un objeto URL
            const urlObj = new URL(url);
            // Obtener el pathname (ruta) de la URL
            const pathname = urlObj.pathname;
            // Dividir el pathname en segmentos
            const segments = pathname.split('/');
            // Obtener el último segmento, que en este caso podría ser el ID del usuario
            const userId = segments[segments.length - 1];
            
            const user_statistic = api.get(`https://backend.teloprogramo.cl/api/exercise/user_info/${userId}`).then(data => {  
                setUserStatistic(data);
                console.log('user_statistic', data);
                return data;
            });
        }
        fetchUserStatistic();
    }
        , []);


    function Charts() {
        const [user_statistic, setUserStatistic] = useState(null);
        useEffect(() => {
            async function fetchUserStatistic() {
                // Obtener la URL completa
                const url = window.location.href;
                // Crear un objeto URL
                const urlObj = new URL(url);
                // Obtener el pathname (ruta) de la URL
                const pathname = urlObj.pathname;
                // Dividir el pathname en segmentos
                const segments = pathname.split('/');
                // Obtener el último segmento, que en este caso podría ser el ID del usuario
                const userId = segments[segments.length - 1];
                const user_statistic = api.get(`https://backend.teloprogramo.cl/api/exercise/user_info/${userId}`).then(data => {
                    
                    setUserStatistic(data);
                    return data;
                });
            }
            fetchUserStatistic();
        }
            , []);

    return (
        <>
            {user_statistic ? (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full justify-between">
                    <div>
                        <BarChart data={user_statistic} />
                    </div>
                    <div>
                        <MultiSeriesPieChart data={user_statistic} />
                    </div>
                    <div>
                        <RadarChart data={user_statistic} />
                    </div>
                </div>
            ) : (
                <div>Cargando gráficos...</div>
            )}
        </>
    );

    }





    const [userInfo, setUserInfo] = useState({});
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [campus, setCampus] = useState('');
    const [institution, setInstitution] = useState('');
    const [semester, setSemester] = useState('');
    const [section, setSection] = useState('');
    const [userrole, setUserrole] = useState('');
    const [ problems, setProblems] = useState([]);
    const [userData, setUserData] = useState({});
    const [subject, setSubject] = useState('');
    const [user_statistic, setUserStatistic] = useState({});
    const user_id = jwtDecode(localStorage.getItem('token')).user_data.user_id;


    /* const [history, setHistory] = useState([{
        id:'1',
        grade:'55',
        difficulty:'easy',
        contents:'loops',
        pass:true,
        property:'NADIE'
    },
    {
        id:'2',
        grade:'54',
        difficulty:'easy',
        contents:'loops',
        pass:false,
        property:'NADIE'
    }]) */


    return (
        <>


            <NavBar />
            <div className="bg-gray-100 dark:bg-gray-900 min-h-screen flex flex-row-reverse items-center justify-center text-black dark:text-white">
    <div className="container mx-auto py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 px-4">
            <div className="col-span-1 sm:col-span-2">
                <div className="bg-gray-200 dark:bg-gray-800 shadow rounded-lg p-6 mb-5 ">
                    <ProfileCard profile={{
                        name: userInfo && userInfo.user ? userInfo.user.name : '',
                        rol: userInfo && userInfo.user ? userInfo.user.roles : '',
                        curso: userInfo && userInfo.user ? userInfo.user.subject.subject : '',
                        paralelo: userInfo && userInfo.user ? userInfo.user.subject.sections : '',
                        campus: userInfo && userInfo.user ? userInfo.user.campus : '',
                        semestre: userInfo && userInfo.user ? userInfo.user.semester : '',
                        institution: userInfo && userInfo.user ? userInfo.user.institution
                        : '',
                        coins: coins, userId: userId
                    }} />
                </div>

                <div className="col-span-full sm:col-span-4 md:col-span-9">
                    <div className="bg-gray-200 dark:bg-gray-800 flex flex-col items-center shadow rounded-lg p-6 w-full">
                        <h1 className="text-xl font-bold py-2 pb-3">Estadísticas</h1>
                        {Charts()}
                    </div>
                </div>
            </div>
            <div className="col-span-1 sm:col-span-2">
                <div className="bg-gray-200 dark:bg-gray-800 rounded-lg flex flex-col items-center shadow p-6">
                    <h1 className="text-xl font-bold mt-4">Historial</h1>
                    <div className="scrollbar rounded-lg flex flex-col  items-start overflow-auto w-full">
                        {problems.slice(0, 5).map((element) => (
                            <ExerciseCard key={element.id} title={element.title} id={element.exercise_id}  difficulty={element.difficulty} subject={element.subject} score={element.result} maxScore={element.max_score} />
                        ))}
                    </div>
                    <Link to={`/history/${userId}`} className="btn btn-primary w-full md:w-auto md:h-[2rem] mt-8">Ver todos mis intentos</Link>
                </div>
            </div>
        </div>
    </div>
</div>
            <Footer />
        </>
    );
};

export default Profile;
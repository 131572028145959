import React, { useState } from 'react';
import ExerciseForm from './ExerciseForm';
import TestCaseForm from './TestCaseForm';
import CodeStub from './CodeStub';
import api from '../../../features/auth/api';

const FormWithSteps = ({ onNewExercise, onExerciseUpdated, onNewTestCase, onFinalStepForm }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 4; 
  const [exerciseId, setExerciseId] = useState(null);
  const [exercise, setExercise] = useState();

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };


  const handleNewExercise = async (newExercise) => {
    setExerciseId(newExercise.exercise_id);

    try {
      const url = `https://backend.teloprogramo.cl/api/exercise/${newExercise.exercise_id}`;

      const response = await api.get(url);
      if (response.status != 200) throw new Error('Failed to fetch');
      const data = await response.data;
      setExercise(data);
      onNewExercise(data);
      handleNextStep();
    } catch (error) {
      console.error(error);
    }
  }

  const handleUpdatedExercise = async () => {
    try {
      const url = `https://backend.teloprogramo.cl/api/exercise/${exerciseId}`;
      const response = await api.get(url);
      if (response.status != 200) throw new Error('Failed to fetch');
      const data = await response.data;
      setExercise(data);
      onExerciseUpdated(data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleFinalStepComplete = async () => {
    try {
      const url = `https://backend.teloprogramo.cl/api/exercise/${exerciseId}`;
      const response = await api.get(url);
      if (response.status != 200) throw new Error('Failed to fetch');
      const data = await response.data;
      setExercise(data);
      onFinalStepForm(data);
    } catch (error) {
      console.error(error);
    }
   };


  return (
    <div>
      {currentStep === 1 && (
        <ExerciseForm
          onNewExercise={handleNewExercise}
          //onExerciseUpdated={onExerciseUpdated}
          onNextStep={handleNextStep}
        />
      )}
      {(currentStep === 2 || currentStep === 3) && (
        <TestCaseForm
          exerciseId={exerciseId}
          inForm={true}
          //isFinalStep={currentStep === totalSteps}
          //onFinalStepComplete={handleFinalStepComplete}
          onUpdate={handleUpdatedExercise}
          onNewTestCase={onNewTestCase}
          onNextStep={handleNextStep}
        />
      )}
      {currentStep === 4 && (
        <CodeStub 
          //onNextStep={handleNextStep}
          isFinalStep={currentStep === totalSteps}
          onFinalStepComplete={handleFinalStepComplete}
          exerciseId={exerciseId}
        />
      )}

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ul className="steps">
          <li className={`step ${currentStep === 1 ? 'step-primary' : ''}`}>Exercise Information</li>
          <li className={`step ${currentStep === 2 ? 'step-primary' : ''}`}>Test Case 1</li>
          <li className={`step ${currentStep === 3 ? 'step-primary' : ''}`}>Test Case 2</li>
          <li className={`step ${currentStep === 4 ? 'step-primary' : ''}`}>Code Stub</li>
        </ul>
      </div>

    </div>
  );
};

export default FormWithSteps;

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ImageLight from '../assets/img/create-account-office.jpeg'
import ImageDark from '../assets/img/login-office-dark.jpeg'
import api from '../features/auth/api';

const RegisterForm = () => {

  const handleSubmit = (e) => {
    e.preventDefault()
    const registerData = {
        usuario: {
          email: formData.email,
          nombre: formData.nombre +  " " + formData.apellido,
          roles: [
            {
              nombre: "ESTUDIANTE"
            }
          ],
          rol_usm: formData.rol_usm,
          password: formData.password
          },
        asignatura: formData.asignatura,
        paralelo: formData.paralelo,
        semestre: formData.semestre,
      }
      api.post(process.env.REACT_APP_ENDPOINT_CREATE_USER, registerData)
      .then(response => {
        if (response.status === 201) {
          alert("¡Te has registrado exitosamente!");
        }
      })
      .catch(error => {
        alert("¡Ha ocurrido un error al registrarse!");
      });
  }

  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    email: '',
    telefono: '',
    password: '',
    confirmPassword: '',
    description: '',
    rol_usm: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  return (
    <>
     <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:hidden"
              src={ImageLight}
              alt="Office"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src={ImageDark}
              alt="Office"
            />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Crear cuenta
              </h1>
              <label className='label'>
                <span className='label-text'>Nombre</span>
                <input className="input input-bordered" type="text" placeholder="Federico" name='nombre' required onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Apellido</span>
                <input className="input input-bordered" type="text" placeholder="Santa María" name='apellido' required onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Rol</span>
                <input className="input input-bordered" type="text" placeholder="Estudiante" name='rol' disabled  onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Email</span>
                <input className="input input-bordered" type="email" placeholder="nombre.apellido@usm.cl" name='email' required onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Rol USM</span>
                <input className="input input-bordered" type="text" placeholder="202030521-2" name='rol_usm' required onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Teléfono</span>
                <input className="input input-bordered" type="text" placeholder="+56912345678" name='telefono' required onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Contraseña</span>
                <input className="input input-bordered" placeholder="***************" type="password" name='password' required onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Confirmar contraseña</span>
                <input required='required' className="input input-bordered" placeholder="***************" type="password" name='confirmPassword' onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Descripción</span>
                <input className="input input-bordered" placeholder="Ingresa una breve descripción de ti para que los demás te conozcan :) " type="text" name='description' onChange={handleChange} />
              </label>
              <label className="label">
                <span className='label-text'>Asignatura</span>
                <select className="select select-bordered " name='asignatura' onChange={handleChange}>
                  <option disabled="disabled" selected="selected">Selecciona una asignatura</option>
                  <option value="TEL-1">TEL-1</option>
                  <option value="TEL-1">TEL-2</option>
                  <option value="TEL-3">TEL-3</option>
                  <option value="TEL-4">TEL-4</option>
                  <option value="TEL-5">TEL-5</option>
                </select>
              </label>
              <label className="label">
                <span className='label-text'>Paralelo</span>
                <select className="select select-bordered " name='paralelo' onChange={handleChange}>
                  <option disabled="disabled" selected="selected">Selecciona un paralelo</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </label>
              <label className="label">
                <span className='label-text'>Semestre</span>
                <select className="select select-bordered " name='semestre' onChange={handleChange}>
                  <option disabled="disabled" selected="selected">Selecciona un semestre</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </label>


              <label className="label mb-3" check>
                <input type="checkbox"  className="checkbox" checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)}/>
                <span className="label-text">
                  Estoy de acuerdo con las <span className="underline">políticas de privacidad</span>
                </span>
              </label>

              <button tag={Link} to="/login" block className="btn btn-outline btn-success" onClick={handleSubmit} disabled={!acceptedTerms}>
                Crear cuenta
              </button>

              <hr className="my-8" />


              <p className="mt-4">
                <Link
                  className="text-sm font-medium text-blue-600 hover:underline"
                  to="/login"
                >
                  Ya tienes cuenta? Ingresa acá
                </Link>
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>

    
    
    
    
    
    </>
  )
}

export default RegisterForm
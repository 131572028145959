import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const MultiSeriesPieChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    const failedAttempts = data.data.total_attempts - data.data.correct_attempts;
    const chart = new Chart(myChartRef, {
      type: "pie",
      data: {
        labels: ['N° intentos exitosos','N° intentos no exitosos']  ,
        datasets: [
          {
            label: "Ejercicios",
            backgroundColor: ['#34D399', '#F87171'],
            data: [data.data.correct_attempts, failedAttempts]
        },
          
        
        ]
      },
      options: {
        responsive: true,
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        }
      }
    });
    
    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <canvas ref={chartRef} />
  );
};

export default MultiSeriesPieChart;

import React from 'react'
import NoLoginNavbar from '../components/NoLoginNavbar'
import RegisterForm from '../components/RegisterForm'
import Footer from '../components/Footer'
const RegisterPage = () => {
  return (
    <>
    <NoLoginNavbar/>
    <RegisterForm/>
    <Footer/>
    </>
  )
}

export default RegisterPage
import React from 'react'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import BrowseProblems from '../components/BrowseProblems';

const Test = () => {
  return (
    <>
    <NavBar/>
    <BrowseProblems/>
    
    <Footer/>
    </>
  )
}

export default Test
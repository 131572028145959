import React, { useState, useEffect } from 'react';

const TagsInput = ({ existingTags, onTagsChange, suggestions }) => {
  const [tags, setTags] = useState(existingTags || []);
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  
  useEffect(() => {
    setTags(existingTags || []);
  }, [existingTags]);

  useEffect(() => {
    setFilteredSuggestions(suggestions);
  }, [suggestions]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    const filtered = suggestions.filter(suggestion => 
      suggestion.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredSuggestions(filtered);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      const newTag = inputValue.trim();
      if (!tags.includes(newTag)) {
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        onTagsChange(updatedTags);
      }
      setInputValue('');
    }
  };

  const removeTag = (indexToRemove) => {
    const updatedTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(updatedTags);
    onTagsChange(updatedTags);
  };

  const addTagFromSuggestion = (tag) => {
    if (!tags.includes(tag)) {
      const updatedTags = [...tags, tag];
      setTags(updatedTags);
      onTagsChange(updatedTags);
    }
    setInputValue('');
  };



  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 100);
  };

  return (
    <div>
      <div className="flex flex-wrap gap-2 p-2 border border-gray-300 rounded-lg">
        {tags.map((tag, index) => (
          <div key={index} className="flex items-center gap-2 bg-blue-500 rounded px-2 py-1 text-gray-900 dark:text-white">
            <span>{tag}</span>
            <button 
              type="button"
              onClick={(e) => {
              removeTag(index);
              e.stopPropagation();
            }} 
            className="text-sm">&times;
            </button>
          </div>
        ))}
      </div>




      {/* Tags inputs */}
      <div className="relative"> 
        <label className="form-control w-full max-w-xs">
          <input 
            type="text" 
            placeholder="Añadir Tag ..." 
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            className="input input-bordered input-sm w-full max-w-xs" 
          />
          <div className="label">
            <span className="label-text-alt">Presione enter para agregar</span>
          </div>
        </label>
        {/* Sugerencias */}
        {isFocused && inputValue && filteredSuggestions.length > 0 && (
          <div className="absolute w-full bg-white border border-gray-300 z-10 mt-1 rounded-b-lg dark:text-black">
            {filteredSuggestions.map((suggestion, index) => (
              <div
                key={index}
                className="p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => addTagFromSuggestion(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
      
    </div>



  );
};

export default TagsInput;

import React, { useState, useEffect, useRef } from 'react';

const CodeEditor = ({ initialValue, label, onChange  }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue); 
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  // Input al momento de editar el formulario
  useEffect(() => {
    setInputValue(initialValue || '');
  }, [initialValue]);


  return (
    <div className="relative">
      <label htmlFor="input-editor" className="text-sm font-semibold text-gray-300 block mb-2">
        {label}
      </label>
      <div className="flex">
        <div className="bg-gray-700 text-xs text-gray-400 p-1 border-r border-gray-600" style={{ minWidth: '2em' }}>
          {Array.from({ length: inputValue.split('\n').length }, (_, i) => (
            <div key={i} className="h-4 w-4 text-right">
              {i + 1}
            </div>
          ))}
        </div>
        <textarea
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          className="form-textarea flex-1 w-full p-1 text-xs text-white bg-gray-900 border-0 focus:ring-0"
          rows="10"
          style={{ lineHeight: 'normal', resize: 'none' }}
          placeholder="Type your code here..."
        ></textarea>
      </div>
    </div>
  );
};

export default CodeEditor;
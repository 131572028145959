import React, { useState, useCallback, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { monokai } from "@uiw/codemirror-theme-monokai";
import { loadLanguage } from "@uiw/codemirror-extensions-langs";
import api from '../features/auth/api';
import { set } from "date-fns";
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { FaCoins } from "react-icons/fa6";



const CodeRun = ({ currentProblem, coins, user_id, problem_state }) => {
  const API_KEY = "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";
  const [editorValue, setEditorValue] = useState("");
  const [outputText, setOutputText] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);
  const [helpText, setHelpText] = useState('');
  const [helpRequestsCount, setHelpRequestsCount] = useState(0);
  const [resultado, setResultado] = useState(null);
  const [resultados, setResultados] = useState([]);
  const [resultadoSubmitCode, setResultadoSubmitCode] = useState(null);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isExecuteClicked, setIsExecuteClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [codeCoins, setCodeCoins] = useState(coins);

  const handleEditorChange = useCallback((value, viewUpdate) => {
    setEditorValue(value);
  }, []);


  async function getChatCompletion() {
    try {
      const messages = [
        { role: 'system', content: 'Tú eres un analizador de código.' },
        {
          role: 'user',
          content:
            'Tengo el siguiente código en python y quiero que me identifiques errores si es que existen. De ser así, dime la línea del error, pero no lo arregles. La estructura de la respuesta quiero que sea la siguiente: "Explicación del error"\n explicas el error y luego abajo: "Ejemplo de corrección"\n y brindas un ejemplo. Este es el código original a analizar:\n' +
            editorValue + '. Este es el contexto del problema que debo resolver para que me des sugerencias al respecto.' + currentProblem.files_data.problem_statement,
        },
      ];
      //console.log('messages', messages.content);

      if (needHelp) {
        messages.push({ role: 'user', content: helpText });
      }

      const res = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + API_KEY,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: messages,
        }),
      });

      const data = await res.json();
      setOutputText(data.choices[0].message.content);
      setShowResponse(true);

      if (needHelp) {
        setHelpRequestsCount(helpRequestsCount + 1);
      }
    } catch (error) {
      console.error('Error al obtener la respuesta de la API:', error);
    }
  }
  async function sentToApi(feedback_inicial) {
    try {
      const body = {
        id_ejercicio: 1,
        id_usuario: 1,
        tiempo: "10:00:00", //debe haber algun contador
        codigo: "print('Hello World')",
        feedback_inicial: feedback_inicial,
      };
      const resIntento = await api.post('/exercise/attempt',
        JSON.stringify(body)
      );
      const intento_data = await resIntento.json()
      //console.log(intento_data)

    }
    catch (err) {
      console.error('Error al obtener el token:', err)
    }
  }


  async function executeCode(code) {
    try {

      const body =
      {
        "exercise_id": currentProblem.exercise_id,
        "time": "10:00:00",
        "code": code
      }
      const resExecute = await api.post(
        "exercise/execute_code", body
      );
      const intento_data = resExecute;
      //console.log(intento_data);
      return intento_data;
    } catch (resExecute) {
      console.error("Error al obtener la respuesta en la ejecución:", resExecute);
    }
  }




  async function sendCode(code) {
    try {

      const body =
      {
        "exercise_id": currentProblem.exercise_id,
        "time": "10:00:00",
        "code": code
      }
      const resIntento = await api.post(
        "exercise/attempt", body
      );
      const intento_data = resIntento;
      //console.log(intento_data);
      return intento_data;
    } catch (resIntento) {
      console.error("Error al obtener la respuesta:", resIntento);
    }
  }
  const handleGenerateButtonClick = async () => {
    // // Ocultar la respuesta antes de obtener una nueva
    if (coins > 0) {
      const newCoins = api.patch(`https://backend.teloprogramo.cl/api/user/remove_coins/${user_id}`,JSON.stringify({ coins: 1 })
      ).then(data => {
        //console.log("new coins:", data);
        setCodeCoins(data.data.coins);
        //updateCoins(data.coins);
        return data;
      });
    }
    else {
      alert('No tienes suficientes monedas para solicitar ayuda.');
      return;
    }
    //const feedback_inicial = await getChatCompletion();
    //await sentToApi(feedback_inicial);
    //setShowResponse(false);
    setNeedHelp(true);
    await getChatCompletion();
  };



  const useCasesPublicResults = async () => {
    const response = await executeCode(editorValue);
    //console.log('response', response);
    if (response !== undefined) {
      const resultados = response.data.detail_use_cases
        .map((useCase, index) => ({
          resultado: useCase.state,
          resultadoObtenido: useCase.obtained,
          useCaseValue: currentProblem.use_cases[index].is_sample
        }));
      setResultados(resultados);
      //console.log('resultados', resultados);

      const resultado = resultados
        .filter(useCase => useCase.useCaseValue)
        .every((useCase) => useCase.resultado);
      setResultado(resultado);
      //console.log('resultado', resultado);
    }
    setIsSubmitClicked(false);
    setIsExecuteClicked(true);
  }
  const useCasesPrivateResults = async () => {
    const response = await sendCode(editorValue);
    //console.log('response', response);
    if (response !== undefined) {
      const resultadosSubmitCode = response.data.detail_use_cases
        .map((useCase, index) => ({
          resultado: useCase.state,
          resultadoObtenido: useCase.obtained,
          useCaseValue: currentProblem.use_cases[index].is_sample
        }));
      setResultados(resultadosSubmitCode);
      const resultadoSubmitCode = resultadosSubmitCode.every((useCase) => useCase.resultado);
      setResultadoSubmitCode(resultadoSubmitCode);
      if(response.data.result === true){
        //console.log("entro al if de result true", user_id);
        const newCoins = api.patch(`https://backend.teloprogramo.cl/api/user/add_coin/${user_id}`,
          JSON.stringify({ coins: 1 })
        ).then(data => {
          //console.log("new coins:", data);
          setCodeCoins(data.coins);
          //updateCoins(data.coins);
          return data;
        });
      }
    
    }

    setIsExecuteClicked(false);
    setIsSubmitClicked(true);


  }
  return (
    <div className="flex flex-col items-center p-5 mt-4 w-full h-full"> {/* Ajustes para que tome todo el espacio disponible */}
      <CodeMirror
        value={editorValue}
        placeholder={"---Escribe tu código aquí---"}
        width="60vw"
        height='60vh'
        theme={monokai}
        extensions={loadLanguage('python')}
        onChange={handleEditorChange}
        className="border border-gray-300 rounded-md"
      />
      {!problem_state ? <div className="flex space-x-4 mt-2 ">
        <button
          onClick={useCasesPublicResults}
          className="btn btn-outline btn-info"
        >
          Ejecutar Código
        </button>



        <button onClick={useCasesPrivateResults} className="btn btn-outline btn-success">Enviar Código</button>
        <button
          className="btn btn-outline btn-warning"
          onClick={handleGenerateButtonClick}
        >
          Necesitas ayuda
        </button>
      </div> :
        <div className="flex space-x-4 mt-2 ">
      Ya has resuelto este problema 😉
      </div>}

      

      {/* Aquí se integra el código para mostrar el mensaje de éxito o error */}
      {isExecuteClicked && (resultado ? (
        <div className="card lg:card-side bg-base-100 shadow-xl mt-5 w-full">
          <div className="card-body">
            <strong className="font-bold">Felicidades!</strong>
            <span className="block sm:inline">Has pasado los casos de uso públicos. Pulsa el botón de enviar código para evaluar todos los casos de uso.</span>
            <div role="tablist" className="tabs tabs-bordered">
              {currentProblem.use_cases.map((useCase, index) => (
                useCase.is_sample &&
                <React.Fragment key={index}>
                  <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label={`Caso de uso ${index + 1}  ${resultados[index].resultado ? '✔️' : '❌'}`} checked={index === selectedTab} onChange={() => setSelectedTab(index)} />

                  <div role="tabpanel" className="tab-content p-10">
                    <h1 className="font-bold mb-2">Tus valores de entrada</h1>
                    <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
                      <div className="text-left font-mono text-sm space-y-1">
                        <pre>{useCase.input_code}</pre>
                      </div>
                    </div>
                    <h1 className="font-bold mb-2">Tus valores de salida</h1>
                    <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
                      <div className="text-left font-mono text-sm space-y-1">
                        {resultados[index].useCaseValue &&
                          <pre>{resultados[index].resultadoObtenido} </pre>
                        }
                      </div>
                    </div>
                    <strong className="font-bold mb-2">Valores esperados</strong>
                    <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
                      <div className="text-left font-mono text-sm space-y-1">
                        {useCase.is_sample && <pre>{useCase.output_code}</pre>}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      ) :

        (
          <div className="card lg:card-side bg-base-100 shadow-xl mt-5 w-full">
            <div className="card-body">
              <strong className="font-bold">Te has equivocado :c</strong>
              <span className="block sm:inline">No has pasado los casos de uso público.</span>
              <div role="tablist" className="tabs tabs-bordered">
                {currentProblem.use_cases.map((useCase, index) => (
                  useCase.is_sample &&
                  <React.Fragment key={index}>
                    <input type="radio"
                      name="my_tabs_1"
                      role="tab"
                      className="tab"
                      aria-label={`Caso de uso ${index + 1}  ${resultados[index].resultado ? '✔️' : '❌'}`}
                      checked={index === selectedTab}
                      onChange={() => setSelectedTab(index)}
                      style={{ whiteSpace: 'nowrap' }} />

                    <div role="tabpanel" className="tab-content p-10">
                      <h1 className="font-bold mb-2">Tus valores de entrada</h1>
                      <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
                        <div className="text-left font-mono text-sm space-y-1">
                          <pre>{useCase.input_code}</pre>
                        </div>
                      </div>
                      <h1 className="font-bold mb-2">Tus valores de salida</h1>
                      <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
                        <div className="text-left font-mono text-sm space-y-1">
                          {resultados[index].useCaseValue &&
                            <pre>{resultados[index].resultadoObtenido} </pre>
                          }
                        </div>
                      </div>
                      <strong className="font-bold mb-2">Valores esperados</strong>
                      <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
                        <div className="text-left font-mono text-sm space-y-1">
                          {useCase.is_sample && <pre>{useCase.output_code}</pre>}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>

            </div>
          </div>
        ))}
      {isSubmitClicked && (resultadoSubmitCode ? (
        <div className="card lg:card-side bg-base-100 shadow-xl mt-5 w-full">
          <div className="card-body">
            <strong className="font-bold">Felicidades!</strong>
            <span className="block sm:inline">Has resuelto el ejercicio de forma correcta.</span>
            <h1 className="font-bold mb-2">Tus valores de salida</h1>
            <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
              <div className="text-left font-mono text-sm space-y-1">
                {resultados.map((useCase, index) => (
                  <p key={index}>Caso de uso {index} {useCase.resultado ? <span className="ml-2 text-green-500">✔️</span> : <span className="ml-2 text-red-500">❌</span>}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (

        <div className="card lg:card-side bg-base-100 shadow-xl mt-5 w-full">
          <div className="card-body">
            <strong className="font-bold">Te has equivocado :c</strong>
            <span className="block sm:inline">No has pasado todos los casos de uso.</span>
            <h1 className="font-bold mb-2">Tus valores de salida</h1>
            <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 p-4 w-full max-w-lg">
              <div className="text-left font-mono text-sm space-y-1">
                {resultados.map((useCase, index) => (
                  <p key={index}>Caso de uso {index} {useCase.resultado ? <span className="ml-2 text-green-500">✔️</span> : <span className="ml-2 text-red-500">❌</span>}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
      {showResponse && (
        <div className="text-gray-500 mt-5 p-5 border border-gray-300 overflow-auto w-full max-h-[calc(100vh-100px)]">
          <h2 className="text-lg text-gray-700 font-sans">Resultado:</h2>
          <ReactMarkdown>{outputText}</ReactMarkdown>
        </div>
      )}


    

      {needHelp && (
        <div className="mt-5 w-[60vw] dark:bg-gray-800 bg-white p-5 rounded-lg shadow-md">
          <div className="flex items-center text-lg mb-2">
            <span>Tienes una pregunta adicional: (Tienes&nbsp;&nbsp;</span>
            <span>{codeCoins}&nbsp;&nbsp;</span>
            <FaCoins />
            <span>&nbsp;&nbsp;restantes)</span>
          </div>
          <textarea
            value={helpText}
            onChange={(e) => setHelpText(e.target.value)}
            className="resize-none w-full h-24 mb-2 p-2 text-sm border border-gray-300 rounded-md"
          />
          <button
            onClick={handleGenerateButtonClick}
            className="bg-green-600 text-white px-5 py-2 rounded-md cursor-pointer text-sm"
          /* disabled={coins  <= 0 ? false : true} */
          >
            Enviar Pregunta
          </button>
        </div>
      )}




    </div>
  );
}

export default CodeRun;
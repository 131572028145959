import React, { useEffect, useState } from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import api from '../features/auth/api';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';

async function getRanking(subject) {
    //console.log(subject);
    const data = await api.get(`/exercise/ranking-subject/${subject}/`, { timeout: 5000 });
    //console.log(data.data);
    return data.data;
}

const RankingPage = () => {
    const [rankings, setRankings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const tokenData = jwtDecode(localStorage.getItem('token'));
        if (tokenData && tokenData.user_data) {
        } else {
            console.log("No hay datos de usuario");
        }
        const fetchData = async () => {
            let subjectModified = tokenData.user_data.subject.subject;
            const rankingData = await getRanking(subjectModified);
            //console.log(rankingData);
            setRankings(rankingData);
        };
        fetchData();
    }, []);

    const pageCount = Math.ceil(rankings.length / itemsPerPage);
    const currentRankings = rankings.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <NavBar />
            <br></br>
            <div className="overflow-x-auto mt-8">
                <table className="table">
                    {/* head */}
                    <thead>
                        <tr>
                            <th></th>
                            <th>Rank</th>
                            <th>Estudiante</th>
                            <th>Resueltos</th>
                            <th>Porcentaje</th>
                            <th>Puntaje</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRankings.map((ranking, index) => (
                            <tr key={index}>
                                <th></th>
                                <td>
                                    <div className="flex items-center gap-3">
                                        <div>
                                        {((currentPage - 1) * itemsPerPage) + index + 1}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex items-center">
                                        <img className="w-10 h-10 rounded-full mr-2" src={`https://ui-avatars.com/api/?name=${encodeURIComponent(ranking.user_details.name)}&background=random&color=fff&length=2&bold=true&size=128`} alt="User" />
                                        <Link to={`/profile/${ranking.user_id}`} className="text-sm">
                                            {ranking.user_details.name}
                                        </Link>
                                    </div>
                                </td>
                                <td>{ranking.exercises_completed}</td>
                                <th>
                                    {parseFloat(ranking.success_rate).toFixed(2)}%
                                </th>
                                <th>
                                    {ranking.total_score}
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-center join mt-8 ">
                {Array.from({ length: pageCount }, (_, i) => i + 1).map(number => (
                    <button
                        key={number}
                        onClick={() => setCurrentPage(number)}
                        className={`join-item btn ${currentPage === number ? 'btn-active' : ''}`}
                    >
                        {number}
                    </button>
                ))}
            </div>
            <Footer />
        </>
    );
}

export default RankingPage;
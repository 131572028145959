import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ExerciseEdit from './ExerciseEdit';
import ExerciseForm from './ExerciseForm';
import api from '../../../features/auth/api';

const ExerciseDetails = () => {
  const { exerciseId } = useParams();
  const [exercise, setExercise] = useState(null);

  useEffect(() => {
    const fetchExerciseDetails = async () => {
      try {
        //const url = `http://localhost:4000/exercises/${exerciseId}`;
        const url = `https://backend.teloprogramo.cl/api/exercise/${exerciseId}`;
        const response = await api.get(url);
        if (response.status != 200) throw new Error('Failed to fetch exercise details');
        const data = await response.data;
        setExercise(data);
      } catch (error) {
        console.error(error);
      }
    };

    if (exerciseId) {
      fetchExerciseDetails();
    }
  }, [exerciseId]);


/*   const handleExerciseUpdated = (updatedExercise) => {
    setExercise(updatedExercise);
    console.log("aca al actualizar en handle",updatedExercise, "el exercise", exercise)

  }; */


  const handleExerciseUpdated = (updatedExerciseData) => {
    // Se normalizan los datos par que siempre queden con la misma estructura del back
    const normalizedData = {
      ...updatedExerciseData, 
      files_data: {
        ...updatedExerciseData.files_data, 
        problem_statement: updatedExerciseData.problem_statement, 
      },
    };
  
    delete normalizedData.problem_statement;
    setExercise(normalizedData);
  };
  
  const handleNewExercise = (newExercise) => {
    setExercise([...exercise, newExercise]);
  };

  return (
    <div className="exercise-details">
      {exercise ? (
        <ExerciseForm
          onNewExercise={handleNewExercise}
          exerciseToEdit={exercise}
          onExerciseUpdated={handleExerciseUpdated}
          
        />
      ) : (
        <p>Cargando detalles del ejercicio...</p>
      )}

    </div>
  );
};

export default ExerciseDetails;

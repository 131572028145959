import React from 'react'
import { FaGlobe, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
<>

<footer className="footer footer-center p-10 dark:bg-base-100 bg-base-200 dark:text-primary-content text-primary-dark">
  <aside>
    <p className="font-bold dark:text-white text-black">
      Ingeniería Civil Telemática <br /> Universidad Técnica Federico Santa María
    </p> 
    <p className="font-bold dark:text-white text-black">Copyright © 2024 - Todos los derechos reservados</p>
  </aside> 
  <nav>
  <div className="grid grid-flow-col gap-4 font-bold dark:text-white text-black">
      <a href="https://telematica.usm.cl/" target="_blank" rel="noopener noreferrer">
      <FaGlobe size={25}/> {/* Ícono de web */}

      </a>
      <a href="https://www.instagram.com/telematicausm/" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={25} /> {/* Ícono de Instagram */}
      </a>
    </div>
  </nav>
</footer>
</> 
)
}

export default Footer
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DoughnutChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    const chart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: ["Fácil", "Media", "Difícil"], // Las etiquetas de las secciones del gráfico
        datasets: [
          {
            label: "Ejercicios",
            data: [10, 30, 5], // Los valores para cada sección
            backgroundColor: [
              '#00baad',
              '#ffc300',
              '#add45c'             
              
            ],
            hoverOffset: 4
          }
        ]
      },
      options: {
        responsive: true,
        animation: {
            animateScale: true,
            animateRotate: true
        },
        plugins: {
          datalabels: {
            // Cambia la posición de las etiquetas si es necesario
            // Cambia la alineación de las etiquetas si es necesario
            color: 'white',
            font: {
              size: 14,
              weight: 'bold', // Ajusta el tamaño de la fuente aquí
              // Ajusta el color de la fuente aquí
            }
          }
        }
    }
    });
    return () => {
      chart.destroy();
    };


  }, []);



  return (
    <canvas ref={chartRef} />
  );
};

export default DoughnutChart;

import React, { useEffect, useRef, useState } from 'react';
import CodeMirror from "@uiw/react-codemirror";
import { monokai } from '@uiw/codemirror-theme-monokai';
import { loadLanguage} from '@uiw/codemirror-extensions-langs';
import ReactMarkdown from 'react-markdown';


const MarkdownEditor = ({ id, value, onChange  }) => {
  const inputRef = useRef(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const editorRef = useRef(null);

  // Code snippet
  const initialLines = new Array(2).fill('\n').join('');
  const [codeSnippet, setCodeSnippet] = useState(initialLines);
  const [language, setLanguage] = useState('javascript');

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };
  // Para actualizar el textarea automatico
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.max(inputRef.current.scrollHeight, 150); 
      inputRef.current.style.height = `${newHeight}px`;
    }
  }, [value]);

  // Para manejer el estado inicial al momento de editar el form
  const handleTextChange = (value) => {
    onChange(value );
  };

  // Agregar negrita
  const addBoldText = () => {
    if (editorRef.current && editorRef.current.view) {
        const editor = editorRef.current.view;
        const { state } = editor;
        const selection = state.selection.main; 
        const selectedText = state.doc.sliceString(selection.from, selection.to); 
        const boldText = `**${selectedText}**`; 
        const transaction = state.update({
            changes: { from: selection.from, to: selection.to, insert: boldText }
        });


        editor.update([transaction]);
    }
  };


  // Lista
  const addBulletList = () => {
    onChange(`${value}\n- `);
  };

  // Cursive
  const addEmphasis = () => {
    if (editorRef.current && editorRef.current.view) {
      const editor = editorRef.current.view;
      const { state } = editor;
      const selection = state.selection.main; 
      const selectedText = state.doc.sliceString(selection.from, selection.to); 
      const emphasis = `*${selectedText}*`; 
      const transaction = state.update({
          changes: { from: selection.from, to: selection.to, insert: emphasis }
      });


      editor.update([transaction]);
  }
  };



  const addNumberedList = () => {
    if (editorRef.current && editorRef.current.view) {
      const view = editorRef.current.view;
      const { state } = view;
  
      const cursorPos = state.selection.main.head;
      const cursorLine = state.doc.lineAt(cursorPos);
      const lines = state.doc.toString().split('\n');
      
      
      let number = 1;
      let isNewList = true; 
  
      
      for (let i = cursorLine.number - 1; i >= 0; i--) {
        const lineMatch = lines[i].match(/^(\d+)\.\s+/);
        if (lineMatch) {
          number = parseInt(lineMatch[1], 10) + 1; 
          isNewList = false; 
          break;
        }
        
        if (lines[i].match(/^\s*$/)) {
          isNewList = true;
          number = 1;
          break;
        }
      }
  

      let insertText = isNewList ? '1. ' : `${number}. `;
      const changes = {
        from: cursorLine.to, 
        insert: '\n' + insertText 
      };
  
      view.dispatch({
        changes: changes,
        selection: { anchor: cursorLine.to + insertText.length + 1 }
      });
  
      if (onChange) {
        onChange(state.doc.toString());
      }
    }
  };
 

  const openModal = () => {
    document.getElementById(`${id}-markdownPreviewModal`).showModal();
  };

  const closeModal = () => {
    document.getElementById(`${id}-markdownPreviewModal`).close();
  };

  //imagen
  const openNewImageModal  = () => {
    document.getElementById(`${id}-newImageModal`).showModal();
  };
  
  const closeNewImageModal = () => {
    document.getElementById(`${id}-newImageModal`).close();
  };

  const handleInsertNewImage = (e) => {
    e.preventDefault();
    const imageUrl = document.getElementById(`${id}-image-url`).value;
    const imageAlt = document.getElementById(`${id}-image-description`).value;
    const markdownImage = `![${imageAlt}](${imageUrl})`;
    onChange(value + '\n' + markdownImage);
    closeNewImageModal(); 
  };

  //Code snippet
  const handleInsertCodeSnippet = () => {
    try {
      const formattedCodeSnippet = `\`\`\`${language}\n${codeSnippet}\n\`\`\``;
      onChange(value + '\n' + formattedCodeSnippet);
      setCodeSnippet('');
      closeModal();
    } catch (error) {
      console.error("Error al insertar el código", error);
    }
  };
  const openCodeSnippet  = () => {
    document.getElementById(`${id}-codeSnippet`).showModal();
  };
  
  const closeCodeSnippet= () => {
    document.getElementById(`${id}-codeSnippet`).close();
  };
  
  //Link
  const handleInsertLink = (e) => {
    e.preventDefault();
    const title = document.getElementById(`${id}-titleLink`).value;
    const linkValue = document.getElementById(`${id}-linkValue`).value;
    const markdownLink = `[${title}](${linkValue})`;
    onChange(value + '\n' + markdownLink);
    closeLink();
  };
  const openLink  = () => {
    document.getElementById(`${id}-link`).showModal();
  };
  
  const closeLink= () => {
    document.getElementById(`${id}-link`).close();
  };


  return (
    <div className="w-full overflow-x-auto">
    <form>
      <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 overflow-x-auto whitespace-nowrap">
        <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
          <div className="flex items-center space-x-2">
            {/* Bold */}
            <button
              type="button"
              className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
              onClick={addBoldText}
            >
              <svg className="w-[15px] h-[15px] fill-[#8e8e8e]" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"></path>
              </svg>
            </button>

            {/* Cursiva */}
            <button type="button" 
              className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
              onClick={addEmphasis}>
              <svg className="w-[15px] h-[15px] fill-[#8e8e8e]" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"></path>
              </svg>
            </button>
            
           {/* - */}
            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600" onClick={addBulletList}>
            <svg className="w-[15px] h-[15px] fill-[#8e8e8e]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"></path>
              </svg>
            </button>

            {/* Listado */}   
            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600" onClick={addNumberedList}>
                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 18">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.5 3h9.563M9.5 9h9.563M9.5 15h9.563M1.5 13a2 2 0 1 1 3.321 1.5L1.5 17h5m-5-15 2-1v6m-2 0h4"/>
                </svg>
            </button>

            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600" onClick={openNewImageModal}>
              <svg className="w-[18px] h-[18px] fill-[#8e8e8e]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path>
              </svg>
            </button>

            {/* Code snippet */}
            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600" onClick={openCodeSnippet}>
              <svg className="w-[18px] h-[18px] fill-[#8e8e8e]" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"></path>
              </svg>
            </button>

            {/* Link */}
            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600" onClick={openLink}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[18px] h-[18px] fill-[#8e8e8e]" viewBox="0 0 16 16">
                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"/>
                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
              </svg>
            </button>


          </div>


          <div>
            <button
              type="button"
              className="btn btn-outline"
              onClick={openModal}
            >
              Preview
            </button>
          </div>

        </div> 
        <CodeMirror 
          value={value} 
          theme={monokai} 
          ref={handleEditorDidMount} 
          extensions={loadLanguage('python')}
          onChange={(value, viewUpdate)=>{handleTextChange(value)}} 
        />

        

        {/* Preview */}
        <dialog id={`${id}-markdownPreviewModal`} className="modal">
          <div className="modal-box relative w-full max-w-4xl h-auto">
            <form method="dialog">
              <button 
                type="button" 
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                onClick={closeModal}
              >✕</button>
            </form>
            <h3 className="font-bold text-lg">Markdown Preview</h3>
            <div className="py-4 overflow-auto">
              {/* <ReactMarkdown remarkPlugins={[remarkGfm]} children={value} /> */}
{/*               <ReactMarkdown remarkPlugins={[remarkGfm]}>{value}</ReactMarkdown>
              <ReactMarkdown markdownit={md}>{value}</ReactMarkdown> */}
              <ReactMarkdown>{value}</ReactMarkdown> 

            </div>
          </div>
        </dialog>

        {/* imagen */}
        <dialog id={`${id}-newImageModal`}  className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <h3 className="font-bold text-lg" style={{ marginBottom: '20px' }}>Insert Image URL</h3>
            <div>
              <input type="text" placeholder="descripción"  id={`${id}-image-description`} className="input input-bordered w-full max-w-xs" style={{ marginBottom: '20px' }} />
            </div>
            <div >
              <input type="text" placeholder="Link de la imagen" id={`${id}-image-url`} className="input input-bordered w-full max-w-xs" />
            </div>
          
            <div className="modal-action">
              <form method='dialog'> 
                <button className="btn" style={{marginRight: '10px' }} onClick={handleInsertNewImage}>Agregar</button>
                <button className="btn" onClick={closeNewImageModal}>Cerrar</button>
             </form>

            </div>
          </div>
        </dialog>

      {/* Code snippet */}
      <dialog id={`${id}-codeSnippet`} className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg" style={{ marginBottom: '20px' }}>Add a Code Snippet</h3>
          <div>
            <div>
              <select 
                value={language} 
                onChange={(e) => setLanguage(e.target.value)} 
                className="select select-bordered select-sm w-full max-w-xs"
                style={{ marginBottom: '20px' }}
              >
                <option value="javascript">JavaScript</option>
                <option value="python">Python</option>
                <option value="c">C</option>
                {/* <option value="C++">C++</option> */}

              </select>          
            </div>

            <div >
              <CodeMirror
                value={codeSnippet}
                theme={monokai}
                extensions={loadLanguage(language)}
                onChange={(value) => setCodeSnippet(value)}
                options={{
                  mode: language,
                }}
              />
            </div>
          </div>
          

          <div className="modal-action">
            <form method='dialog'>
              <button className="btn" style={{ marginRight: '10px' }} onClick={handleInsertCodeSnippet}>Add</button>
              <button className="btn" onClick={closeCodeSnippet}>Close</button> 
            </form>
          </div>

        </div>
      </dialog>

    {/* Link */}
    <dialog id={`${id}-link`}  className="modal modal-bottom sm:modal-middle">
      <div className="modal-box">
        <h3 className="font-bold text-lg" style={{ marginBottom: '20px' }}>Insert Link</h3>
        <div>
          <input type="text" placeholder="Title"  id={`${id}-titleLink`} className="input input-bordered w-full max-w-xs" style={{ marginBottom: '20px' }} />
        </div>
        <div >
          <input type="text" placeholder="Link" id={`${id}-linkValue`} className="input input-bordered w-full max-w-xs" />
        </div>
      
        <div className="modal-action">
          <form method='dialog'> 
            <button className="btn" style={{marginRight: '10px' }} onClick={handleInsertLink}>Agregar</button>
            <button className="btn" onClick={closeLink}>Cerrar</button>
        </form>

        </div>
      </div>
    </dialog>

        
    </div>
    </form>



      

    </div>
  );
};


export default MarkdownEditor;
import React, { useEffect, useState } from 'react';
import logo1 from '../assets/img/foto_teloprogramo_2.png';
import { Stack } from '@mui/material';
import { FaCoins } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import api from '../features/auth/api';
import { jwtDecode } from 'jwt-decode';
const ProfileCard = ({ profile }) => {
  
  const jwtDecoded = jwtDecode(localStorage.getItem('token'));

  const [picture, setPicture] = React.useState(null);
  React.useEffect(() => {
    const url = window.location.href;
    // Crear un objeto URL
    const urlObj = new URL(url);
    // Obtener el pathname (ruta) de la URL
    const pathname = urlObj.pathname;
    // Dividir el pathname en segmentos
    const segments = pathname.split('/');
    // Obtener el último segmento, que en este caso podría ser el ID del usuario
    const userId = segments[segments.length - 1];
    api.get(`https://backend.teloprogramo.cl/api/user/get-picture/${userId}`).then(data => {
      const picture = decodeURIComponent(data.data.picture).slice(7).replace('https:', 'https:/');
      console.log(picture);
      setPicture(picture);
      return data;
    });
  }
    , []);
  /* const handleImageUpload = event => {
    const file = event.target.files[0];
    
    const reader = new FileReader();
    reader.onloadend = () => {
      setPicture(reader.result); 
    };
    reader.readAsDataURL(file);
  }; */

  const handleImageUpload = event => {
    const file = event.target.files[0];
    //console.log("first file", file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPicture(reader.result);
    };
    reader.readAsDataURL(file);
    const newPhoto = {
      name: file.name,
      type: file.type,
    }
    // Crear un objeto FormData
    const formData = new FormData();
    //console.log(file)
    formData.append('picture', newPhoto);
    formData.append('user_id', profile.user_id);
    //console.log(formData);

    const response = api.post('user/upload-picture/', JSON.stringify(formData));
    //console.log(response);
  };

  const handleEditButtonClick = () => {
    document.getElementById('file-upload').click();
    //TODO: Agregar la foto a la base de datos
  };





  return (
    <div className="flex flex-col sm:flex-row justify-around items-center sm:items-start">
      <div className="relative m-8 sm:mr-4">
        <img className="w-32 h-32 object-cover border-2 border-gray-300 dark:border-gray-700" src={decodeURIComponent(picture)} alt="Profile picture" />
        <input type="file" accept="image/*" onChange={handleImageUpload} id="file-upload" style={{ display: 'none' }} />
      </div>

      <div className="px-6 py-4 flex-grow">
        <div className="font-bold text-xl mb-2 dark:text-white">Perfil </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div>
            <p className="text-gray-900 dark:text-white text-base ">Nombre: {profile.name}</p>
            <p className="text-gray-900 dark:text-white text-base">Rol: {profile.rol}</p>
            <p className="text-gray-900 dark:text-white text-base">Curso: {profile.curso}, Paralelo: {profile.paralelo} </p>
            <p className="text-gray-900 dark:text-white text-base">Campus: {profile.campus}</p>
          </div>
          <div>
            <p className="text-gray-900 dark:text-white text-base">Semestre: {profile.semester}</p>
            <p className="text-gray-900 dark:text-white text-base">Universidad: {profile.institution}</p>
            <div className='flex gap-1'>
              <FaCoins /> Monedas : {profile.coins}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
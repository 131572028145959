import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ExerciseCard from '../components/CardExercise';
import api from '../features/auth/api';
import { jwtDecode } from 'jwt-decode';
async function getProblemsHistory(userId) {
    const data = await api.get(`https://backend.teloprogramo.cl/api/exercise/exercises-per-user/${userId}`);
    return data.data;

}

const ProblemHistory = () => {
    const tokenData = jwtDecode(localStorage.getItem('token'));

    //let userId = tokenData.user_data.user_id;
    //console.log('userId', userId);

    useEffect(() => {


        // Obtener la URL completa
        const url = window.location.href;
        // Crear un objeto URL
        const urlObj = new URL(url);
        // Obtener el pathname (ruta) de la URL
        const pathname = urlObj.pathname;
        // Dividir el pathname en segmentos
        const segments = pathname.split('/');
        // Obtener el último segmento, que en este caso podría ser el ID del usuario
        const userId = segments[segments.length - 1];


        getProblemsHistory(userId).then(data => {
            setProblems(data);
        }).catch(error => {
            console.error("Error al cargar problemas:", error);
        });
    }, []);

    const [problems, setProblems] = useState([]);

    return (
        <>
            <NavBar />
            <div className="col-span-1 sm:col-span-2">
                <div className="bg-base-100 dark:bg-gray-800 rounded-lg flex flex-col items-center shadow p-6">
                    <h1 className="text-xl font-bold mt-4">Historial</h1>
                    {/* flex-wrap */}
                    <div className="scrollbar rounded-lg flex flex-col  items-start overflow-auto w-full">
                        {problems.map((element) => (
                            <ExerciseCard key={element.id} title={element.title} id={element.exercise_id} difficulty={element.difficulty} subject={element.subject} score={element.result} maxScore={element.max_score} />

                        ))}
                    </div>
                </div>
            </div>

            <Footer />



        </>
    )
}

export default ProblemHistory
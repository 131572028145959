import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { monokai } from "@uiw/codemirror-theme-monokai";
import { useParams } from "react-router-dom";
import api from "../../../features/auth/api";

const CodeStub = ({ isFinalStep, onFinalStepComplete, exerciseId }) => {
  const [headCode, setHeadCode] = useState("");
  const [bodyCode, setBodyCode] = useState("");
  const [tailCode, setTailCode] = useState("");
  //const { exerciseId } = useParams();
  const { exerciseId: exerciseIdFromParams } = useParams();
  const effectiveExerciseId = exerciseId || exerciseIdFromParams;
  const [successMessage, setSuccessMessage] = useState("");


  const handleCodeChange = (value, container) => {
    switch (container) {
      case "head":
        setHeadCode(value);
        break;
      case "body":
        setBodyCode(value);
        break;
      case "tail":
        setTailCode(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchExerciseDetails = async () => {
      try {
        //const url = `http://localhost:4000/exercises/${exerciseId}`;
        const url = `https://backend.teloprogramo.cl/api/exercise/${effectiveExerciseId}`;

        const response = await api.get(url);
        if (response.status != 200) throw new Error("Failed to fetch exercise details");
        const data = await response.data;
        //setBodyCode(data.bodyCode || '');
        setTailCode(data.tail || '');
        setHeadCode(data.head || '');
        
      } catch (error) {
        console.error(error);
      }
    };

    if (effectiveExerciseId) {
      fetchExerciseDetails();
    }
  }, [effectiveExerciseId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const codeStub = {
      subject: "TEL101",
      head: headCode,
      //body: bodyCode,
      tail: tailCode,
    };
    const url = `https://backend.teloprogramo.cl/api/exercise/update/${effectiveExerciseId}/`;

    try {
      const response = await api.put(url, codeStub);

      if (response.status === 200) {
        const result = await response.data;
        if (!isFinalStep) {
          setSuccessMessage("Changes successfully saved!");
          scrollToSuccessMessage();
        }
        if (isFinalStep) {
          onFinalStepComplete();
        }
      } else {
        throw new Error("Submission failed");
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }

  };

  const scrollToSuccessMessage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <div>
      {successMessage && (
        <div className="bg-green-500 text-white p-4 mb-4 rounded">
            {successMessage}
        </div> 
      )}
      <form onSubmit={handleSubmit}>
        <h1>Head</h1>
        <CodeMirror
          value={headCode}
          height="40px"
          theme={monokai}
          onChange={(value) => handleCodeChange(value, "head")}
        />
        <h1>Body</h1>
        <CodeMirror
          value={bodyCode}
          height="200px"
          theme={monokai}
          onChange={(value) => handleCodeChange(value, "body")}
        />
        <h1>Tail</h1>
        <CodeMirror
          value={tailCode}
          height="40px"
          theme={monokai}
          onChange={(value) => handleCodeChange(value, "tail")}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button
            type="submit"
            //className="px-5 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            className="btn btn-outline btn-success"
          >
            Guardar Cambios
          </button>
        </div>
      </form>
    </div>
  );
};

export default CodeStub;

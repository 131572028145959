import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import CodeEditor from '../components/CodeEditor';
import TestCases from '../components/TestCases';
import CodeRun from '../components/CodeRun';
import { jwtDecode } from 'jwt-decode';
import ReactMarkdown from 'react-markdown';
import api from '../features/auth/api';

// Función para obtener los problemas desde la API


async function getProblems() {
    const data = await api.get('/exercise/list');
    return data.data.results;
}

const SolvingProblems = () => {
    const [problemas, setProblemas] = useState([]);
    const [currentProblem, setCurrentProblem] = useState(null);
    const [selectedDifficulty, setSelectedDifficulty] = useState('Todas');
    const [selectedTab, setSelectedTab] = useState(0); // Añade esta línea

    const { id } = useParams(); // ID del problema
    const [coins, setCoins] = useState(0);
    const [user_id, setUserId] = useState('');

    useEffect(() => {
        async function fetchCoins() {
            const user_id = jwtDecode(localStorage.getItem('token')).user_data.user_id;
            setUserId(user_id);
            const coins = await api.get(`https://backend.teloprogramo.cl/api/user/get_coin/${user_id}`).then(data => {
                //cast to int
                //console.log("coins fetching", data.data.coins);
                let coins = parseInt(data.data.coins);
                //console.log(user_id)
                //console.log("conins solving problems", coins);
                setCoins(coins);


                return data;
            });
        }
        fetchCoins();
    }, []);



    useEffect(() => {
        getProblems().then(data => {
            setProblemas(data);
            // Encontrar el problema que coincide con el ID
            const problemaEncontrado = data.find(problema => problema.exercise_id.toString() === id);
            setCurrentProblem(problemaEncontrado);
        }).catch(error => {
            console.error("Error al cargar problemas:", error);
        });
    }, [id]); // Dependencia de 'id' para que se actualice si cambia el ID en la URL

    // Asegurarse de que currentProblem no sea null para mostrar detalles
    if (!currentProblem) {
        return <>

            <span className="loading loading-ring loading-xs"></span>
            <span className="loading loading-ring loading-sm"></span>
            <span className="loading loading-ring loading-md"></span>
            <span className="loading loading-ring loading-lg"></span>
        </>;
    }

    return (
        <>
            <NavBar />
            <div className="relative  flex flex-col md:flex-row min-h-screen bg-base-100 "> {/* Cambio para responsividad */}
                <div className="flex-1 p-4 ml-8"> {/* Ajusta el padding según necesites */}

                    <div key={id} className="mb-4">
                        <h1 className="text-xl font-bold mb-2">{currentProblem.title}</h1>
                        
                        <ReactMarkdown>{currentProblem.files_data.problem_statement}</ReactMarkdown>

                        <div className="mb-4">
                            <h1 className="text-xl font-bold mb-2">Formato de entrada</h1>
                            <ReactMarkdown>{currentProblem.input_format}</ReactMarkdown>
                        </div>
                        <div className="mb-4">
                            <h1 className="text-xl font-bold mb-2">Restricciones</h1>
                            <ReactMarkdown>{currentProblem.constraints}</ReactMarkdown>
                        </div>
                        <div className="mb-4">
                            <h1 className="text-xl font-bold mb-2">Formato de salida</h1>
                            <ReactMarkdown>{currentProblem.output_format}</ReactMarkdown>
                        </div>
                        <div>

                            <div role="tablist" className="tabs tabs-bordered">
                                {currentProblem.use_cases.map((useCase, index) => (
                                    useCase.is_sample && (
                                        <React.Fragment key={index}>
                                            <input
                                                type="radio"
                                                name="my_tabs_2"
                                                role="tab"
                                                className="tab"
                                                aria-label={`Ejemplo ${index + 1}`}
                                                checked={index === selectedTab} // Modifica esta línea
                                                onChange={() => setSelectedTab(index)} // Añade esta línea
                                            />
                                            <div role="tabpanel" className="tab-content p-10">
                                                {/* Ejemplo de entrada */}
                                                <div>
                                                    <h2 className="text-xl font-bold mb-2">Ejemplo de entrada {index + 1}</h2>
                                                    <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border dark:border-gray-600 border-gray-200 p-4 w-full max-w-lg mb-3 overflow-auto">
                                                        <div className="text-left font-mono text-sm space-y-1 overflow-wrap break-words w-full">
                                                            <pre>{useCase.input_code}</pre>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Ejemplo de salida */}
                                                <div>
                                                    <h2 className="text-xl font-bold mb-2">Ejemplo de salida {index + 1}</h2>
                                                    <div className="bg-gray-100 dark:bg-gray-800 rounded-lg border dark:border-gray-600 border-gray-200 p-4 w-full max-w-lg mb-3 overflow-auto">
                                                        <div className="text-left font-mono text-sm space-y-1 overflow-wrap break-words w-full">
                                                            <pre>{useCase.output_code}</pre>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                ))}
                            </div>
                        </div>

                    </div>


                </div>

                <div className="flex-1 px-4 flex flex-col"> {/* Cambio para que tome la mitad de la pantalla */}
                    <CodeRun currentProblem={currentProblem} coins={coins} user_id={user_id} problem_state={currentProblem.user_attempts_info.resolved} /> {/* Componente del editor de código */}
                    <div className="w-full">
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default SolvingProblems;

import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let facil = data && data.data.difficulty_completed && data.data.difficulty_completed.Fácil !== undefined ? data.data.difficulty_completed.Fácil : 0;
    let medio = data && data.data.difficulty_completed && data.data.difficulty_completed.Medio !== undefined ? data.data.difficulty_completed.Medio : 0;
    let dificil = data && data.data.difficulty_completed && data.data.difficulty_completed.Difícil !== undefined ? data.data.difficulty_completed.Difícil : 0;
    //console.log(facil, medio, dificil);
    const myChartRef = chartRef.current.getContext("2d");
    const chart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: ["Fácil", "Media", "Difícil"], // Las etiquetas de las barras
        datasets: [
          {
            label: "Dificultad de ejercicios",
            data: [facil, medio, dificil],
            backgroundColor: [
              '#00baad',
              '#ffc300',
              '#add45c'
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: 14,
              weight: 'bold',
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              color: 'rgba(255, 255, 255, 0.3)', // Cambia el color aquí, en este caso a blanco
            }
          },
          x: {
            grid: {
              color: 'rgba(255, 255, 255, 0.3)', // Cambia el color aquí, en este caso a blanco
            }
          }
        }
      }
    });
    if (chart) {
      return () => {
        chart.destroy();
      };
    }
  }, []);

  return (
    <canvas ref={chartRef} width={400} height={300} /> // Ajusta el ancho y el alto según tus necesidades

  );
};

export default BarChart;

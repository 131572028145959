import React, { useState, useEffect, useRef } from 'react';
import MarkdownEditor from './MarkdownEditor';
import api from '../../../features/auth/api';


//Categorias
const categories = [
	'Condicionales',
	'For',
	'While',
	'Funciones',
	'Listas',
	'Diccionarios',
	'Tuplas'
];


const ExerciseEdit = ({ exercise, onExerciseUpdated }) => {
	const [problemName, setProblemName] = useState(exercise?.problemName || '');
  const [problemStatement, setProblemStatement] = useState(exercise?.problemStatement || '');
  const [inputFormat, setInputFormat] = useState(exercise?.inputFormat || '');
  const [constraints, setConstraints] = useState(exercise?.constraints || '');
  const [outputFormat, setOutputFormat] = useState(exercise?.outputFormat || '');
  const [language, setLanguage] = useState(exercise?.language || 'Español');
  const [programmingLanguage, setProgrammingLanguage] = useState(exercise?.programmingLanguage || 'Python');
	const [contenidos, setContenidos] = useState(exercise?.categories || [] );
	const [difficulty, setDifficulty] = useState(exercise?.difficulty || 'Fácil');
  const [subject, setSubject] = useState(exercise?.subject || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [createdBy, setCretedBy] = useState(exercise?.createdBy || 'PROFESOR');
  const [binary, setBinary] = useState(exercise?.binary || false);
  const [successMessage, setSuccessMessage] = useState('');
  const [score, setScore] = useState(exercise?.score || 0);
  const successMessageRef = useRef(null);



  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setCretedBy("PROFESOR1")
    const exerciseData = {
				problemName,
        subject,
        problemStatement,
        inputFormat,
        constraints,
        outputFormat,
        language,
        programmingLanguage,
				difficulty,
				categories: contenidos,
        createdBy,
        binary,
        score
    
    };
    const url = `http://localhost:4000/exercises/${exercise._id}`
    try
    {
        const response = await api.put(url, 
            

            JSON.stringify(exerciseData));

        if (response.ok) {
            const result = await response.json();
            onExerciseUpdated(result);
            setSuccessMessage('Changes successfully saved!');
            scrollToSuccessMessage();
            //Para reiniciar el estado 
            //setProblemStatement('');
            //setInputFormat('');
            //setConstraints('');
            //setOutputFormat('');
            //navigate('/exercises');
          } else {
            throw new Error('Submission failed');
          }

        } catch (error) {
            console.error('Error during submission:', error);
        };
    };

    useEffect(() => {
        if (successMessage) {
          const timer = setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
          return () => clearTimeout(timer);
        }
      }, [successMessage]);

    const scrollToSuccessMessage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //Contenidos
    const handleCheckboxChange = (cont) => {
        setContenidos((prevSelectedContent) => {
            if (prevSelectedContent.includes(cont)) {
                return prevSelectedContent.filter((selectedCategory) => selectedCategory !== cont);
            } else {
                return [...prevSelectedContent, cont];
            }
        });
    };

    useEffect(() => {
    }, [contenidos]);

    
    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

  return (
        <div>
            {successMessage && (
                <div className="bg-green-500 text-white p-4 mb-4 rounded">
                    {successMessage}
                </div> 
            )}
            <div ref={successMessageRef}></div>
            <div className="mx-auto max-w-4xl mt-8 p-4 bg-gray-800 rounded shadow-lg">
                <form onSubmit={handleSaveChanges} className="space-y-6">
                {/*Binary*/}
                <div className="grid grid-cols-3 gap-4">
                  <div className="flex items-center mr-4">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={binary}
                      onChange={() => setBinary(!binary)}
                      id="checkboxDefault" />
                    <label
                      className="inline-block pl-[0.15rem] hover:cursor-pointer"
                      htmlFor="checkboxDefault">
                      Binary
                    </label>
                  </div>
                  <div class="flex items-center">
                    <label
                      className="inline-block pr-[0.15rem] hover:cursor-pointer"
                      htmlFor="scoreInput">
                      Score: 
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      id="scoreInput"
                      value={score}
                      onChange={(e) => setScore(e.target.value)}
                      className="input input-bordered input-info w-full h-7 max-w-xs" />
                  </div>
                </div>

                
                <div class="grid grid-cols-3 gap-5">
                    <div>
                      {/*Language*/}
                      <div className="mb-4">
                          <label className="text-white block">Language:</label>
                          <div className="relative inline-block text-left w-full">
                            <select
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                className="select select-info w-full max-w-xs"
                            >
                                <option value="" disabled hidden> Select Language</option>
                                <option value="English">English</option>
                                <option value="Espanol">Español</option>
                            </select>

                          </div>
                      </div>
                    </div>

                    <div>
                      {/*Progeammin language*/}
                      <div className="mb-4">
                          <label className="text-white block">Programming Language:</label>
                          <div className="relative inline-block text-left w-full">
                          <select
                              value={programmingLanguage}
                              onChange={(e) => setProgrammingLanguage(e.target.value)}
                              className="select select-info w-full max-w-xs"
                          >
                              <option disabled hidden> Select Programming Language</option>
                              <option >Python</option>
                              <option >C</option>
                              <option >C++</option>
                              <option >Java</option>
                          </select>

                          </div>
                      </div>   
                    </div>

                    <div>
                      {/*Dificultad*/}
                      <div className="mb-4">
                          <label className="text-white block">Dificutad:</label>
                          <div className="relative inline-block text-left w-full">
                          <select
                              value={difficulty}
                              onChange={(e) => setDifficulty(e.target.value)}
                              className="select select-info w-full max-w-xs"
                          >
                              <option value="" disabled hidden> Select difficulty</option>
                              <option value="Facil">Fácil</option>
                              <option value="Medio">Medio</option>
                              <option value="Dificil">Dificil</option>
                          </select>

                          </div>
                      </div> 
                    </div>

                </div>
                



								{/*Contenidos*/}
								<button
										id="dropdownBgHoverButton"
										data-dropdown-toggle="dropdownBgHover"
										className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
										type="button"
										onClick={() => setIsDropdownOpen(!isDropdownOpen)}
									>
										Contenidos
										<svg
											className="w-2.5 h-2.5 ms-3"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 10 6"
										>
											<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
										</svg>
									</button>

								{/* Dropdown menu */}
								<div id="dropdownBgHover" className={`z-50 ${isDropdownOpen ? 'block' : 'hidden'} w-48 bg-white rounded-lg shadow dark:bg-gray-700`} style={{ top: 'calc(100% + 10px)', right: '0' }}>
									<ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownBgHoverButton">
										{categories.map((cont, index) => (
											<li key={index}>
												<div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
													<input
														id={`checkbox-item-${index}`}
														type="checkbox"
														value={cont}
														checked={contenidos.includes(cont)}
														onChange={() => handleCheckboxChange(cont)}
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
													/>
													<label htmlFor={`checkbox-item-${index}`} className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
														{cont}
													</label>
												</div>
											</li>
										))}
									</ul>
								</div>

                    <div>
                      {/*Problem Name*/}
                      <div class="mb-6">
                        <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Problem Name</label>
                        <input 
                        type="text" 
                        id="default-input" 
                        value={problemName}
                        onChange={(e) => setProblemName(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      {/*Dependencies*/}
                      <div class="mb-6">
                        <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dependencies</label>
                        <input 
                        type="text" 
                        id="default-input" 
                        value={problemName}
                        onChange={(e) => setProblemName(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      {/*Subject*/}
                      <div class="mb-6">
                        <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
                        <input 
                        type="text" 
                        id="default-input" 
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>


                        Problem Statement
                        <MarkdownEditor
                            value={problemStatement}
                            
                            onChange={(text) => setProblemStatement(text)}
                        />
                        Input Format
                        <MarkdownEditor
                            value={inputFormat}
                            onChange={(code) => setInputFormat(code)}
                        />
                        Constraints
                        <MarkdownEditor
                            value={constraints}
                            onChange={(text) => setConstraints(text)} 
                        />
                        Output Format
                        <MarkdownEditor
                            value={outputFormat}
                            onChange={(code) => setOutputFormat(code)} 
                        />

                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            Guardar Cambios
                        </button>
                    </div>
                </form>
            </div>
        </div>
  );
};

export default ExerciseEdit;
import React from 'react'
import NoLoginNavbar from '../components/NoLoginNavbar'
import Login from '../components/Login'
import Footer from '../components/Footer'
const LoginPage = () => {
  return (
    <>
    <NoLoginNavbar/>
    <Login/>
    <Footer/>
    </>
  )
}

export default LoginPage